import Base from "components/basic/base";
import { DataGridRowData } from "./data-grid-columns";
import Cells from "./cells";
import { useDataGridStore } from "./data-grid-store";
import { lighten } from "@theme-ui/color";
import { useNavigate } from "react-router";

type DataGridRowProps = { rowData?: DataGridRowData };
export const DataGridRow: ComponentWithChildren<DataGridRowProps> = ({
  rowData,
  sx,
  children,
}) => {
  const navigate = useNavigate();
  const selectedRows = useDataGridStore((state) => state.selectedRows);
  const setSelectedRow = useDataGridStore((state) => state.setSelectedRow);
  const getRowId = useDataGridStore((state) => state.getRowId);

  let isSelected = false;
  let rowId: string;
  if (rowData) {
    rowId = getRowId(rowData as object);
    isSelected = selectedRows.has(rowId);
  }

  return (
    <Base
      sx={{
        display: "contents",
        position: "relative",
        cursor: "pointer",
        transition: "all 0.2s",
        ":hover": {
          background: "rgba(0,0,0,0.05)",
        },
        ...(isSelected && {
          background: lighten("primary.light3", 0.05),
          ":hover": {
            background: "primary.light3",
          },
        }),
        ...sx,
      }}
      onClick={() => rowData && navigate(rowId)}
    >
      {rowData ? <Cells rowData={rowData} /> : children}
    </Base>
  );
};
