import { CardSection } from "components/basic/card/card-section";
import Text from "components/basic/text";
import {
  IconBuilding,
  IconMap,
  IconRocket,
  IconCreditCard,
  IconBusinessplan,
  IconPictureInPicture,
  IconPictureInPictureOff,
  IconPictureInPictureOn,
  IconPictureInPictureTop,
  IconX,
} from "@tabler/icons-react";
import { useStepsContext } from "components/steps-controller/steps-context";
import { Step } from "pages/authenticated/early-adopter/steps/step-item";
import RegisterAgency from "pages/authenticated/register-agency/sections/agency";
import RegisterPayment from "pages/authenticated/register-agency/sections/payment/payment";
import RegisterPlans from "pages/authenticated/register-agency/sections/plans";
import AddressForm from "./sections/address";
import IconButton from "components/basic/icon-button";
import Icon from "components/basic/icon";
import { useCrudStore } from "../crud-store";
import { useCallback } from "react";
import { useStoreSettersProperty } from "./context";
import Base from "components/basic/base";

type ContentKeys = "property" | "address" | "images" | "business";

export const content = {
  address: {
    label: "Endereço",
    icon: IconMap,
    content: AddressForm,
  },
  property: {
    label: "Propriedade",
    icon: IconBuilding,
    content: RegisterAgency,
  },
  images: {
    label: "Imagens",
    icon: IconPictureInPictureOff,
    content: RegisterPlans,
  },
  business: {
    label: "Ofertas",
    icon: IconBusinessplan,
    content: RegisterPayment,
  },
};

export const Header: Component = () => {
  const setIsFormVisible = useCrudStore((state) => state.setIsFormVisible);
  const setIsTableVisible = useCrudStore((state) => state.setIsTableVisible);
  const setEditingEntity = useCrudStore((state) => state.setEditingEntity);
  const { set: setContextValues } = useStoreSettersProperty();

  const stepsContext = useStepsContext();
  const { currentStepId, setCurrentStepId } = stepsContext;

  const handleCloseForm = useCallback(() => {
    const url = new URL(window.location.href);
    url.search = "";
    window.history.pushState({}, "", url.href);
    setContextValues({ values: {} });
    setEditingEntity(null);
    setCurrentStepId("address");
    setIsFormVisible(false);
    setIsTableVisible(true);
  }, []);

  return (
    <CardSection
      addLineSeperator
      sx={{ alignItems: "center", gap: 1, justifyContent: "space-evenly" }}
      direction="horizontal"
    >
      <Text variant="h3" sx={{ width: "30%" }}>
        {content[currentStepId as ContentKeys].label}
      </Text>
      {Object.entries(content).map(([key, value], index) => {
        return (
          <Step
            index={index}
            stepKey={key}
            stepValue={{ icon: value.icon, title: value.label }}
            key={key}
          />
        );
      })}
      <Base
        sx={{
          display: "flex",
          flexGrow: 0,
          width: "30%",
          justifyContent: "end",
        }}
      >
        <IconButton size="micro" variant="ghost-text" onClick={handleCloseForm}>
          <Icon as={IconX} />
        </IconButton>
      </Base>
    </CardSection>
  );
};

export default Header;
