import Link from "./link";
import Tooltip from "./tooltip";

type ToolTipButtonProps = { tooltip?: string; href?: string };
export const ToolTipButton: ComponentWithChildren<
  ToolTipButtonProps
> = ({ tooltip, href, children }) => {
  return (
    <Tooltip label={tooltip ?? ""}>
      <Link
        variant="ghost"
        size="auto"
        sx={{
          fontSize: 5,
          flexGrow: 0,
          color: "text.faded",
          p: 1,
          "&:hover": { color: "text.main" },
        }}
        href={href}
      >
        {children}
      </Link>
    </Tooltip>
  );
};