import { withAuthenticationRequired } from "react-oidc-context";
import { RealStates } from "./manage-agencies-cards";
import { ManageAgenciesHeader } from "./manage-agencies-header";

const ManageAgencies = () => {
  return (
    <>
      <ManageAgenciesHeader />
      <RealStates />
    </>
  );
};

export default withAuthenticationRequired(ManageAgencies);
