import { FlexColumn } from "components/basic/flex";
import { HEADER_HEIGHT } from "./side-menu/layout-constants";

const PageContent: ComponentWithChildren = ({
  children,
  sx,
  ...props
}) => {
  return (
    <FlexColumn
      sx={{
        flexGrow: 1,
        height: "100%",
        p: 4,
        px: 6,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: 2,
        zIndex: 2,
        overflowX: "clip",
        ...sx,
      }}
      {...props}
    >
      {children}
    </FlexColumn>
  );
};

export default PageContent;
