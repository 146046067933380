/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PropertiesModel } from "../models/PropertiesModel";
import type { PropertyIn } from "../models/PropertyIn";
import type { SuccessResponse } from "../models/SuccessResponse";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";
import { PropertyFormType, RealEstateProperty } from "services/housien/types";

export class PropertiesService {
  /**
   * Add Property to Agency
   * Add a new property to the specified agency.
   * @param agencyId The ID of the agency.
   * @param requestBody
   * @returns SuccessResponse Property added successfully.
   * @throws ApiError
   */
  public static postProperty(
    agencyId: string,
    requestBody: PropertyIn
  ): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/agencies/{agencyId}/properties",
      path: {
        agencyId: agencyId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get All Properties of an Agency
   * Retrieve all properties associated with a specified agency.
   * @param agencyId The ID of the agency.
   * @param agencyId The ID of the agency.
   * @returns PropertiesModel Properties retrieved successfully.
   * @throws ApiError
   */
  public static getAllProperties(
    agencyId: string
  ): CancelablePromise<Array<PropertiesModel>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/agencies/{agencyId}/properties",
      path: {
        agencyId: agencyId,
      },
    });
  }

  /**
   * Get Property Information
   * Retrieve property information based on the property ID within a specified agency.
   * @param agencyId The ID of the agency.
   * @param propertyId The ID of the property.
   * @returns PropertiesModel Property information retrieved successfully.
   * @throws ApiError
   */
  public static getProperty(
    agencyId: string,
    propertyId: string
  ): CancelablePromise<PropertiesModel> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/agencies/{agencyId}/properties/{propertyId}",
      path: {
        agencyId: agencyId,
        propertyId: propertyId,
      },
    });
  }

  /**
   * Get Link Image Property Information
   * Retrieve property information based on the property ID within a specified agency.
   * @param agencyId The ID of the agency.
   * @param propertyId The ID of the property.
   * @returns PropertiesModel Property information retrieved successfully.
   * @throws ApiError
   */
  public static getLinkImageProperty(
    agencyId: string,
    propertyId: string
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/agencies/{agencyId}/properties/{propertyId}/images",
      path: {
        agencyId: agencyId,
        propertyId: propertyId,
      },
    });
  }

  /**
   * Delete Property
   * Delete property based on the property ID within a specified agency.
   * @param agencyId The ID of the agency.
   * @param propertyId The ID of the property.
   * @returns SuccessResponse Property deleted successfully.
   * @throws ApiError
   */
  public static deleteProperty(
    agencyId: string,
    propertyId: string
  ): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/agencies/{agencyId}/properties/{propertyId}",
      path: {
        agencyId: agencyId,
        propertyId: propertyId,
      },
    });
  }

  /**
   * Delete Property
   * Delete property based on the property ID within a specified agency.
   * @param agencyId The ID of the agency.
   * @param propertyId The ID of the property.
   * @returns SuccessResponse Property deleted successfully.
   * @throws ApiError
   */
  public static updateProperty(
      propertyId: string,
      requestBody: RealEstateProperty,
      agencyId?: string,
  ): CancelablePromise<SuccessResponse> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/agencies/{agencyId}/properties/{propertyId}",
      path: {
        agencyId: agencyId,
        propertyId: propertyId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }
}
