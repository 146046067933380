import { InputMessages } from "components/form/primitives/field/input-message";
import TextInput, { TextInputProps } from "../inputs/text-input";
import { FormFieldProps } from "../form-field.d";
import { FieldWrapper } from "../primitives/field/field-wrapper";

export type TextFieldProps = TextInputProps & FormFieldProps;

const TextField: Component<TextFieldProps> = ({
  name,
  label,
  help,
  type = "text",
  disabled = false,
  leftAdornment,
  rightAdornment,
  sx,
  ...props
}) => {
  // Store input props to be repassed
  const inputProps = {
    type,
    disabled,
    ...props,
  };

  return (
    <FieldWrapper name={name} label={label}>
      <TextInput name={name} {...inputProps} />
      <InputMessages name={name} />
    </FieldWrapper>
  );
};

export const PassField: Component<Omit<TextFieldProps, "type">> = ({
  name,
  ...props
}) => <TextField name={name} type="password" {...props} />;

export const DateField: Component<Omit<TextFieldProps, "type">> = ({
  name,
  ...props
}) => <TextField name={name} type="date" {...props} />;

export default TextField;
