import React, { forwardRef, ReactElement, PropsWithChildren } from "react";

export type Ref = HTMLButtonElement;

export const ForwardRef = forwardRef<Ref, PropsWithChildren>(
  ({ children, ...props }, ref) => {
    // Type check for children to ensure it's a valid React element
    const child = React.Children.only(children) as ReactElement | null;

    return (
      <>{child && React.cloneElement(child, { forwardRef: ref, ...props })}</>
    );
  }
);
