import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";

type StepsContext = {
  currentStepId: string;
  setCurrentStepId: Dispatch<SetStateAction<string>>;
} & StepsProviderProps;

const StepsContext = createContext<StepsContext | undefined>(undefined);

type StepsProviderProps = { stepIdOrder: string[], firstStep?: string };
const StepsProvider: ComponentWithChildren<StepsProviderProps> = ({
  stepIdOrder,
  firstStep,
  children,
}) => {
  const [currentStepId, setCurrentStepId] = useState(firstStep ?? stepIdOrder[0]);

  const contextVariables = {
    currentStepId,
    setCurrentStepId,
    stepIdOrder,
  };
  const initialValues = useMemo(
    () => ({ ...contextVariables }),
    [...Object.values(contextVariables)]
  );

  return (
    <StepsContext.Provider value={initialValues}>
      {children}
    </StepsContext.Provider>
  );
};

export const useStepsContext = () => {
  const context = useContext(StepsContext);
  if (!context)
    throw new Error("This component must be wrapped by StepsContext");
  return context;
};

type UseStepState = (
  stepId: string,
  stepsContext: StepsContext
) => "current" | "history" | "future";
export const useStepState: UseStepState = (stepId, stepsContext) => {
  const { stepIdOrder, currentStepId } = stepsContext;
  const currentIndex = stepIdOrder.indexOf(currentStepId);
  const stepIdIndex = stepIdOrder.indexOf(stepId);
  if (stepIdIndex === -1)
    throw new Error("'stepId' not found in context 'stepIdOrder'");
  if (stepIdIndex > currentIndex) return "future";
  if (stepIdIndex < currentIndex) return "history";
  return "current";
};

export default StepsProvider;
