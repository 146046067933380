import { User, UserProfile } from "oidc-client-ts";
import { LoadingPage } from "pages/other/loading-page";
import { useEffect } from "react";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { OpenAPI, UserService } from "services/requests";

function AuthenticationCallback() {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isLoading) {OpenAPI.TOKEN = auth.user?.access_token;

    const profile = auth.user?.profile as UserProfile;
    const user_name = {
      first_name: profile.given_name || "",
      last_name: profile.family_name || ""
    };

    UserService.createUser(user_name).then(response => {
      console.log(response);
      CallbackInit();
    }).catch(err => {
      if (err.status === 400) CallbackInit();
      console.log(err);
    });}
  }, [auth.isLoading]);

  async function CallbackInit() {
    // try {
    //   if (!auth.isLoading && auth.isAuthenticated) {
    //     const response = await EarlyAdopterService.getEarlyAdopter();
    //     if (response) {
    //       window.location.href = window.location.origin + "/earlyadopter";
    //     } else {
    //       window.location.href =
    //         window.location.origin + "/application/real-states";
    //     }
    //   }
    // } catch (error) {
    //   console.log("Error:", error);
    //   // window.location.href =
    //   //   window.location.origin + "/";
    // }
    const returnPath = localStorage.getItem("returnPath");
    window.location.href = returnPath ? returnPath : window.location.origin + "/earlyadopter";
    localStorage.setItem("returnPath", "");
  }

  return <LoadingPage />;
}

export default withAuthenticationRequired(AuthenticationCallback);
