import { zIndex } from "themes/light/z-index";
import Base from "./base";

type BlackboxProps = {
  blurAmount?: number;
  darkenAmount?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
};
export const Blackbox: Component<BlackboxProps> = ({
  blurAmount = 1,
  darkenAmount = 2,
  children,
  sx,
  ...props
}) => {
  return (
    <Base
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: `darken.n${darkenAmount}`,
        backdropFilter: `blur(${blurAmount}px)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: zIndex.blackbox,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Base>
  );
};
