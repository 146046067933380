import { BiInfoCircle } from "react-icons/bi";
import Base from "components/basic/base";
import Card from "components/basic/card/card";
import { zIndex } from "themes/light/z-index";

type InfoButtonProps = { text: string; };
export const InfoButton: Component<InfoButtonProps> = ({ text }) => {
  return (
    <Base
      sx={{
        cursor: "help",
        position: "relative",
        "&:hover span": {
          opacity: 1,
          height: "auto",
          width: "max-content",
          maxWidth: "200px",
          p: 1,
        },
      }}
    >
      <Base
        as={BiInfoCircle}
        sx={{
          fontSize: 1,
          color: "text.main",
          mx: 1,
        }}
      ></Base>
      <Card
        addOutline
        size="micro"
        as="span"
        sx={{
          position: "absolute",
          top: 0,
          right: "100%",
          height: 0,
          width: 0,
          p: 0,
          transition: "all 0.2s",
          opacity: 0,

          overflow: "hidden",
          zIndex: zIndex.popup,

          fontSize: 0,
          color: "text.main",
        }}
      >
        {text}
      </Card>
    </Base>
  );
};
