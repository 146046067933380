import { Blackbox } from "components/basic/blackbox";
import FormProvider from "components/form/form-store";
import { CardWithSections } from "components/basic/card/card";
import { DataGridColumnData } from "components/basic/data-grid/data-grid-columns";
import { CardSection } from "components/basic/card/card-section";
import { FilterForm } from "./filter-form";
import { FilterHeader } from "./filter-header";
import { cssKeyframes } from "styles/animations";
import { getFilterByDataType } from "components/composed/filters/context/filter-definitions";
import Icon from "components/basic/icon";
import { IconFilterPlus } from "@tabler/icons-react";
import { useCurrentFilter, useFiltersStore } from "./context/filters-store";
import { FilterDataTypes } from "./context/filter-definitions.d";

type FilterPopupProps = {
  columnId: string;
  dataType: FilterDataTypes;
  label?: string;
  visible?: boolean;
  onClose: () => void;
};
const FilterPopup: Component<FilterPopupProps> = ({
  visible: isVisible = false,
  dataType,
  label,
  onClose,
  columnId,
}) => {
  const setFilters = useFiltersStore((state) => state.setFilters);
  const currentColumnFilter = useCurrentFilter(columnId);

  const currentFilterOperation = getFilterByDataType(
    currentColumnFilter?.filterDataType ?? dataType
  ).operations[0].operationType;

  const formInitialValues = {
    operation: {
      value: currentFilterOperation,
    },
    ...currentColumnFilter?.fieldValues,
  };

  if (!isVisible) return <></>;

  return (
    <Blackbox
      onClick={(event) => {
        event.currentTarget === event.target && onClose();
      }}
    >
      <FormProvider initialValues={formInitialValues}>
        <CardWithSections
          size="small"
          sx={{
            display: "flex",
            flexFlow: "column",
            minWidth: "250px",
            animation: `${cssKeyframes.pop} 0.2s backwards`,
            cursor: "default",
          }}
        >
          <CardSection addLineSeperator>
            <FilterHeader columnId={columnId} onClose={onClose}>
              <Icon as={IconFilterPlus} />
              {label ?? columnId}
            </FilterHeader>
          </CardSection>

          <CardSection sx={{ gap: 1 }}>
            <FilterForm
              columnId={columnId}
              dataType={dataType}
              label={label}
              onClose={onClose}
              setFilters={setFilters}
            />
          </CardSection>
        </CardWithSections>
      </FormProvider>
    </Blackbox>
  );
};

export default FilterPopup;
