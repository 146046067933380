import { create } from "zustand";
import {
  FilterDataTypes,
  FilterFieldTypes,
  FilterOperationMetadata,
} from "./filter-definitions.d";
import { filterFieldsMap } from "./filter-fields-map";

type ColumnId = string;
export type Filters = Record<ColumnId, Filter>;
export type Filter = {
  columnId: ColumnId;
  columnLabel: string;
  filterDataType: FilterDataTypes;
  operationType: FilterOperationMetadata["operationType"];
  fieldValues: Record<FilterFieldTypes, any>;
};

export type FiltersStore = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  removeFilters: (filterKeys: ColumnId | ColumnId[]) => void;
  getUrlFilters: () => Record<string, string>;
};

export const useFiltersStore = create<FiltersStore>((set, get) => ({
  filters: {},
  setFilters: (filters) =>
    set((state) => ({ filters: { ...state.filters, ...filters } })),
  removeFilters: (filterKeys) => {
    if (typeof filterKeys === "string") filterKeys = [filterKeys];
    set((state) => {
      const updatedFilters = Object.entries(state.filters).filter(
        ([key, value]) => !filterKeys.includes(key)
      );
      return { filters: Object.fromEntries(updatedFilters) };
    });
  },
  getUrlFilters: () => {
    const filters = get().filters;
    let urlParams: Record<string, string> = {};
    for (let filter of Object.values(filters)) {
      for (let [fieldType, value] of Object.entries(filter.fieldValues)) {
        const fieldDefinition = filterFieldsMap[fieldType as FilterFieldTypes];
        let urlOperator = fieldDefinition.urlOperator;
        urlOperator = urlOperator ? `_${urlOperator}` : "";
        const key = `${filter.columnId}${urlOperator}`;
        urlParams[key] = value;
      }
    }
    return urlParams;
  },
}));

export const useCurrentFilter = (columnId: string): Filter | undefined =>
  useFiltersStore((state) => state.filters[columnId]);
