import {
  IconBracketsContain,
  IconEqual,
  IconEqualNot,
  IconMathEqualGreater,
  IconMathEqualLower,
  IconMathGreater,
  IconMathLower,
} from "@tabler/icons-react";
import {
  FilterFieldDefinition,
  FilterFieldTypes,
} from "./filter-definitions.d";

export const filterFieldsMap = {
  equals: {
    fieldType: "equals",
    label: "Equals To",
    urlOperator: "",
    icon: IconEqual,
  },
  different: {
    fieldType: "different",
    label: "Different From",
    urlOperator: "ne",
    icon: IconEqualNot,
  },
  like: {
    fieldType: "like",
    label: "Like",
    urlOperator: "like",
    icon: IconBracketsContain,
  },
  greater: {
    fieldType: "greater",
    label: "Greater Than",
    urlOperator: "gte",
    icon: IconMathGreater,
  },
  lower: {
    fieldType: "lower",
    label: "Lower Than",
    urlOperator: "lte",
    icon: IconMathLower,
  },
  "greater-or-equals": {
    fieldType: "greater-or-equals",
    label: "Greater Or Equals Than",
    urlOperator: "gte",
    icon: IconMathEqualGreater,
  },
  "lower-or-equals": {
    fieldType: "lower-or-equals",
    label: "Lower Or Equals Than",
    urlOperator: "lte",
    icon: IconMathEqualLower,
  },
} satisfies Record<FilterFieldTypes, FilterFieldDefinition>;
