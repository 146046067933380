import { createContext, useMemo } from "react";
import { Flex } from "../flex";
import { ThemeUIStyleObject } from "theme-ui";

export type CardProps = {
  variant?: "paper" | "invert" | "none" | "outlined";
  size?: "nano"|"unset" | "micro" | "small" | "medium" | "large";
  addOutline?: boolean;
  removeShadow?: boolean;
  direction?: "horizontal" | "vertical";
  paddingless?: boolean;
  color?: string;
};

const Card: ComponentWithChildren<CardProps> = ({
  variant = "paper",
  color = "primary.main",
  direction = "horizontal",
  size = "medium",
  addOutline = false,
  removeShadow = false,
  paddingless = false,
  children,
  sx,
  ...props
}) => {
  return (
    <Flex
      sx={{
        display: "flex",
        ...(direction === "vertical" && { flexDirection: "column" }),
        ...(variant === "paper" && {
          boxShadow: 10,
          background: "background.paper",
        }),
        ...(variant === "invert" && {
          background: "background.default",
          boxShadow: "1px 1px 4px rgba(0,0,0,0.1) inset",
        }),
        ...((variant === "outlined" || addOutline) && {
          border: "1px solid transparent",
          borderColor: "darken.n2",
        }),
        ...(removeShadow && { boxShadow: 0 }),
        ...cardSizeToSx(size),
        ...(paddingless && { p: 0 }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </Flex>
  );
};

export function cardSizeToSx(size: CardProps["size"]) {
  if (size === undefined) return {};
  const sizesSx: Record<string, ThemeUIStyleObject> = {
    unset: { borderRadius: 2, p: 0 },
    micro: { borderRadius: 2, p: 1 },
    small: { borderRadius: 2, p: 2 },
    medium: { borderRadius: 2, p: 3 },
    large: { borderRadius: 3, p: 4 },
  };

  return sizesSx[size];
}

export const CardWithSectionsContext = createContext<Partial<CardProps>>({});
export const CardWithSections: ComponentWithChildren<CardProps> = ({
  size = "medium",
  direction,
  children,
  sx,
  ...props
}) => {
  const contextProps = useMemo(() => ({ size, direction }), [size, direction]);
  return (
    <CardWithSectionsContext.Provider value={contextProps}>
      <Card paddingless={true} direction="vertical" sx={sx} {...props}>
        {children}
      </Card>
    </CardWithSectionsContext.Provider>
  );
};

export default Card;
