import { FlexColumn } from "components/basic/flex";
import Text from "components/basic/text";
import BreadCrumb from "components/composed/breadcrumb";
import Card from "components/basic/card/card";
import FormProvider from "components/form/form-store";
import TextField from "components/form/custom-fields/text-field";
import { toast } from "react-toastify";
import InputAdornment from "components/form/primitives/input-adornment";
import { BiLockAlt } from "react-icons/bi";
import LoadingAdornment from "components/form/primitives/input-adornment/loading";
import PasswordField from "components/form/custom-fields/password-field";
import DropdownField from "components/form/fields/dropdown-field";
import FieldGroup from "components/form/custom-fields/field-group";
import CheckboxField from "components/form/custom-fields/checkbox-field";
import RadioField from "components/form/custom-fields/radio-field";
import MultiCheckChipField from "components/form/custom-fields/multi-check-chip-field";
import { withAuthenticationRequired } from "react-oidc-context";

const RealStateSettings = () => {
  return (
    <>
      <FlexColumn sx={{}}>
        <Text variant="h3">Settings</Text>
        <BreadCrumb />
      </FlexColumn>

      <Card direction="vertical" sx={{ width: "100%", gap: 2 }}>
        <FormProvider initialStates={{ disabled: { isDisabled: true } }}>
          <Section title="Text">
            <TextField
              name="simple-field"
              label="Simple field"
              type="password"
            />

            <TextField
              name="placeholder"
              label="Field with placeholder"
              placeholder="Enter your input here"
            />

            <TextField
              name="help-message"
              label="Help message"
              help="Help message"
            />

            <TextField
              name="events"
              label="Events"
              onFieldChange={({ value }) => {
                toast(`onChange: ${value || "empty"}`, { autoClose: 500 });
              }}
              onFieldBlur={() => toast("onBlur", { autoClose: 500 })}
              onFieldFocus={() => toast("onFocus", { autoClose: 500 })}
            />

            <TextField name="disabled" label="Disabled" />
          </Section>

          <Section title="Adornments">
            <TextField
              name="left-adornment-1"
              label="Left Adornment 1"
              leftAdornment={
                <InputAdornment variant="none">
                  <BiLockAlt />
                </InputAdornment>
              }
            />

            <TextField
              name="left-adornment-2"
              label="Left Adornment 2"
              leftAdornment={
                <InputAdornment variant="filled" rightBorder>
                  <BiLockAlt />
                </InputAdornment>
              }
            />

            <TextField
              name="left-adornment-3"
              label="Left Adornment 3"
              leftAdornment={
                <InputAdornment variant="button" rightBorder>
                  <BiLockAlt />
                </InputAdornment>
              }
            />

            <TextField
              name="loading"
              label="Loading"
              rightAdornment={<LoadingAdornment leftBorder />}
            />
          </Section>

          <Section title="Text">
            <TextField name="date" label="Date picker" type="date" />

            <PasswordField name="password" label="Password" />

            <TextField name="time" label="Time picker" type="time" />

            <DropdownField
              name="select"
              label="Select Field"
              options={["1", "2", "3"]}
            />
          </Section>
          <Section title="Selection">
            <FieldGroup name="" label="Checkbox Group">
              <CheckboxField name="checkbox-1" label="Checkbox example 1" />
              <CheckboxField name="checkbox-2" label="Checkbox example 2" />
              <CheckboxField name="checkbox-3" label="Checkbox example 3" />
            </FieldGroup>

            <FieldGroup name="" label="Radio Group">
              <RadioField
                name="radio-group"
                label="Radio example 1"
                value="Radio value 1"
              />
              <RadioField
                name="radio-group"
                label="Radio example 2"
                value="Radio value 2"
              />
              <RadioField
                name="radio-group"
                label="Radio example 3"
                value="Radio value 3"
              />
            </FieldGroup>

            <MultiCheckChipField
              name="multi-select-chips"
              label="MultiSelectChips"
              options={[
                { label: "Option 1", value: 1 },
                { label: "Option 2", value: 2 },
                { label: "Option 3", value: 3 },
                { label: "Option 4", value: 4 },
                { label: "Option 5", value: 5 },
              ]}
            />
          </Section>
        </FormProvider>
      </Card>
    </>
  );
};

type SectionProperties = { title?: string };
export const Section: ComponentWithChildren<SectionProperties> = ({
  title,
  children,
}) => {
  return (
    <FlexColumn>
      {title ?? ""}
      {children}
    </FlexColumn>
  );
};

export default withAuthenticationRequired(RealStateSettings);
