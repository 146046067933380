import { FlexColumn, FlexFill } from "components/basic/flex";
import Icon from "components/basic/icon";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import SideMenuGroup from "./side-menu-group";
import Button from "components/basic/button";
import { HEADER_HEIGHT, SIDE_MENU_WIDTH } from "./layout-constants";
import { appLayoutContext } from "../layout_context";

export type LinkItem = {
  label: string;
  linkTo: string;
  icon?: ReactNode;
};

export type GroupItem = {
  label?: string;
  linkTo?: string;
  linkItems: Record<string, LinkItem>;
};

export type LinkItems = Record<string, GroupItem>;

export type SideMenuProps = {
  menuVisibility: boolean;
  setMenuVisibility: Dispatch<SetStateAction<boolean>>;
};
const SideMenu: Component<SideMenuProps> = ({
  menuVisibility,
  setMenuVisibility,
}) => {
  const { useStore } = appLayoutContext;
  const linkItems = useStore((state) => state.linkItems) as LinkItems;

  return (
    <FlexColumn
      sx={{
        width: `${SIDE_MENU_WIDTH}px`,
        background: "white",
        height: `calc(100vh - ${HEADER_HEIGHT}px)`,
        position: "sticky",
        top: `${HEADER_HEIGHT}px`,
        zIndex: 1,
        boxShadow: 5,
        transition: "all 0.2s",
        flexShrink: 0,
        cursor: "pointer",
        ...(!menuVisibility && {
          width: "60px",
          "&:hover": { width: "65px", mr: "-5px" },
          p: 0,
        }),
      }}
    >
      <FlexColumn
        sx={{
          gap: "0.8rem",
          py: 1,
          overflow: "clip",
          overflowY: "auto",
          px: 2,
          ...(!menuVisibility && {
            px:0
          })
        }}
      >
        {Object.entries(linkItems).map(([key, group]) => (
          <SideMenuGroup
            key={key}
            label={menuVisibility ? group.label : undefined}
            linkTo={group.linkTo}
          >
            {Object.entries(group.linkItems).map(([linkItemKey, linkItem]) => (
              <SideMenuLink
                key={linkItemKey}
                label={menuVisibility ? linkItem.label : undefined}
                linkTo={linkItem.linkTo}
                icon={linkItem.icon}
                sx={menuVisibility ? undefined : { justifyContent: "center" }}
              />
            ))}
          </SideMenuGroup>
        ))}
      </FlexColumn>

      <FlexFill onClick={() => setMenuVisibility((previous) => !previous)} />
      <Button
        variant="ghost-text"
        sx={{
          width: "100%",
          justifyContent: menuVisibility ? "flex-end" : "center",
          p: 0,
        }}
        onClick={() => setMenuVisibility((previous) => !previous)}
      >
        <Icon
          as={menuVisibility ? BiChevronLeft : BiChevronRight}
          sx={{ width: "30px", height: "30px" }}
        />
      </Button>
    </FlexColumn>
  );
};

export default SideMenu;
