import { FormFieldProps } from "../form-field";
import Dropzone, { DropzoneProps } from "../inputs/dropzone";
import { FieldWrapper } from "../primitives/field/field-wrapper";

type DropzoneFieldProps = FormFieldProps & DropzoneProps;
const DropzoneField: Component<DropzoneFieldProps> = ({
  name,
  label,
  help,
  sx,
  ...props
}) => {
  return (
    <FieldWrapper name={name} label={label} help={help} sx={sx}>
      <Dropzone name={name} {...props} />
    </FieldWrapper>
  );
};

export default DropzoneField;
