import createLocalZustand from "context/local-zustand";
import { LinkItems } from "./side-menu";

interface AppLayoutContextType {
  linkItems: LinkItems | any;
  agency?: Record<string, any>;
  property?: Record<string, any>;
  navigationBar: Array<JSX.Element | null>;
  set: HandleChangeStore;
  get: () => AppLayoutContextType;
  updateNavigationBar: (component: JSX.Element | null, index?: number) => void;
}

export type HandleChangeStore = (
  partial:
    | AppLayoutContextType
    | Partial<AppLayoutContextType>
    | ((
        state: AppLayoutContextType
      ) => AppLayoutContextType | Partial<AppLayoutContextType>),
  replace?: boolean | undefined
) => void;

export type HandleChangeValues = (linkItems: LinkItems) => void;

export const appLayoutContext = createLocalZustand<AppLayoutContextType>(
  (initializer) => (set, get) => ({
    linkItems: { ...initializer },
    agency: undefined,
    property: undefined,
    navigationBar: [],
    set: set,
    get: get,
    updateNavigationBar: (component, index) => {
      set((state) => {
        const updatedNavigationBar = [...state.navigationBar];
    
        if (index !== undefined && index >= updatedNavigationBar.length) {
          const placeholders = Array(index - updatedNavigationBar.length).fill(null);
          updatedNavigationBar.push(...placeholders);
        }
    
        if (index !== undefined && index >= 0) {
          updatedNavigationBar.length = index + 1; 
          updatedNavigationBar[index] = component; 
        }
    
        return { ...state, navigationBar: updatedNavigationBar };
      });
    },    
  })
);