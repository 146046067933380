import {
  IMAGE_MIME_TYPE,
  Dropzone as MantineDropzone,
  MIME_TYPES,
  MS_EXCEL_MIME_TYPE,
  MS_POWERPOINT_MIME_TYPE,
  MS_WORD_MIME_TYPE,
  PDF_MIME_TYPE,
  FileWithPath,
  FileRejection,
} from "@mantine/dropzone";
import { FormInputProps } from "../form-input";
import Card from "components/basic/card/card";
import { useFormStoreFieldValue, useFormStoreSetters } from "../form-store";
import Text from "components/basic/text";
import Icon from "components/basic/icon";
import { IconUpload } from "@tabler/icons-react";
import { FlexCenter, FlexColumn } from "components/basic/flex";
import Base from "components/basic/base";

export const DROPZONE_ACCEPT_TYPES = {
  ...MIME_TYPES,
  IMAGE_TYPES: IMAGE_MIME_TYPE,
  PDF_TYPES: PDF_MIME_TYPE,
  WORD_TYPES: MS_WORD_MIME_TYPE,
  EXCEL_TYPES: MS_EXCEL_MIME_TYPE,
  POWERPOINT_TYPES: MS_POWERPOINT_MIME_TYPE,
};

export type DropzoneProps = {
  idleMessage?: string;
  dropMessage?: string;
  rejectMessage?: string;
  acceptTypes?: string[];
  maxSizeKB?: number;
} & FormInputProps;

export const DropzoneAccept: ComponentWithChildren = ({ children }) => (
  <Base as={MantineDropzone.Accept} sx={{ display: "contents" }}>
    {children}
  </Base>
);

export const DropzoneReject: ComponentWithChildren = ({ children }) => (
  <Base as={MantineDropzone.Reject} sx={{ display: "contents" }}>
    {children}
  </Base>
);

export const DropzoneIdle: ComponentWithChildren = ({ children }) => (
  <Base as={MantineDropzone.Idle} sx={{ display: "contents" }}>
    {children}
  </Base>
);

const Dropzone: Component<DropzoneProps> = ({
  name,
  idleMessage: placeholder = "Upload de arquivos",
  dropMessage: acceptMessage = "Solte o arquivo aqui",
  rejectMessage = "Arquivo não suportado",
  acceptTypes = [
    DROPZONE_ACCEPT_TYPES.png,
    DROPZONE_ACCEPT_TYPES.webp,
    DROPZONE_ACCEPT_TYPES.jpeg,
    DROPZONE_ACCEPT_TYPES.gif,
    DROPZONE_ACCEPT_TYPES.pdf,
  ],
  maxSizeKB = 3 * 1024,
}) => {
  const uploads: FileWithPath[] = useFormStoreFieldValue(name);
  const setters = useFormStoreSetters();
  const { setFieldValue } = setters;

  return (
    <>
      {
        <MantineDropzone
          onDrop={(files: FileWithPath[]) => {
            console.log("ACCEPTED", files);
            setFieldValue(name, files);
          }}
          onReject={(files: FileRejection[]) => {
            console.log("REJECTED", files);
          }}
          maxSize={maxSizeKB * 1024}
          accept={acceptTypes}
        >
          <Card
            variant="invert"
            sx={{ alignItems: "center", cursor: "pointer" }}
          >
            <FlexCenter sx={{ gap: 1 }}>
              <DropzoneAccept>
                <Icon as={IconUpload} />
                <Text>{acceptMessage}</Text>
              </DropzoneAccept>
              <DropzoneReject>{rejectMessage}</DropzoneReject>
              <DropzoneIdle>
                {uploads && (
                  <FlexColumn sx={{ textAlign: "center" }}>
                    <Text sx={{ fontWeight: "bold" }}>Files loaded:</Text>
                    {uploads?.map((upload) => {
                      if (upload.name)
                        return <Text key={upload.name}>{upload.name}</Text>;
                    })}
                  </FlexColumn>
                )}
                {!uploads && (
                  <>
                    <Icon as={IconUpload} />
                    <Text>{placeholder}</Text>
                  </>
                )}
              </DropzoneIdle>
            </FlexCenter>
          </Card>
        </MantineDropzone>
      }
    </>
  );
};

export default Dropzone;
