import { FlexColumn } from "components/basic/flex";
import { ReactNode } from "react";
import Base from "components/basic/base";
import Card from "components/basic/card/card";
import {
  useFormStoreFieldData,
  useFormStoreSetters,
} from "components/form/form-store";
import { zIndex } from "themes/light/z-index";
import { FormFieldProps } from "../form-field";
import Text from "components/basic/text";

export type DropdownFieldOption = { value: any; label?: string };
export type DropdownFieldOptions = Record<string, DropdownFieldOption>;

type DropdownFieldProps = {
  options?: DropdownFieldOptions;
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
  onChange?: (value: DropdownFieldOption) => void;
} & FormFieldProps;

const DropdownField: ComponentWithChildren<DropdownFieldProps> = ({
  name,
  label,
  options,
  help,
  disabled,
  leftAdornment,
  rightAdornment,
  placeholder,
  onChange,
  children,
  sx,
  ...props
}) => {
  const fieldData = useFormStoreFieldData(name);
  const { value, state = {} } = fieldData;
  const { isDisabled = false } = state;

  const setters = useFormStoreSetters();
  const { setFieldValue } = setters;

  const handleChange = (value: DropdownFieldOption) => {
    onChange?.(value);
    setFieldValue(name, value);
  };

  return (
    <FlexColumn sx={{ position: "relative", ...sx }}>
      <Text sx={{ background: "orange", color: "white", p: 1 }}>
        DropdownField DEPRECATED! Please change to combobox or dropdown
      </Text>
      {/* <FieldHeader {...{ name, label, help }} />
      <Listbox value={value} onChange={handleChange}>
        <ListboxButton style={{ display: "contents" }}>
          <InputContainer name={name} sx={{ cursor: "pointer" }}>
            {leftAdornment}
            <InputFill
              name={name}
              value={value?.label ?? value?.value ?? placeholder ?? "Selecione"}
              type="button"
              sx={{ textAlign: "left" }}
            />
            {rightAdornment}
            {!rightAdornment && (
              <InputAdornment variant="none" leftBorder>
                <BiChevronDown />
              </InputAdornment>
            )}
          </InputContainer>
        </ListboxButton>

        <ListboxOptions>
          <OptionsBox>
            {options && <JsonOptions {...{ name, options }} />}
            {children}
          </OptionsBox>
        </ListboxOptions>
      </Listbox>
      <InputMessages name={name} />*/}
    </FlexColumn>
  );
};

const OptionsBox: ComponentWithChildren = ({ children }) => {
  return (
    <Card
      addOutline
      sx={{
        position: "absolute",
        top: "100%",
        left: 0,
        listStyle: "none",
        flexDirection: "column",
        width: "100%",
        overflowY: "auto",
        maxHeight: "155px",
        zIndex: zIndex.default,
      }}
      size="unset"
    >
      {children}
    </Card>
  );
};

type SelectOptionProps = {
  value: any;
  label?: string;
};
export const SelectOption: ComponentWithChildren<SelectOptionProps> = ({
  value,
  label,
  children,
  sx,
  ...props
}) => {
  const optionValue: DropdownFieldOption = { value, label };

  return (
    <>
      {/* <ListboxOption style={{ display: "contents" }} value={optionValue}>
      {({ active, disabled, selected }) => (
        <Base
        sx={{
          py: "micro",
          px: "small",
            whiteSpace: "nowrap",
            cursor: "pointer",
            color: "text.main",
            fontSize: 0,
            ...(active && {
              background: "primary.light3",
              color: "primary.dark3",
            }),
            ...(selected && {
              background: "primary.main",
              color: "primary.contrastText",
            }),
            // TODO Implement disabled
            ...(disabled && {}),
            ...sx,
          }}
          {...props}
          >
          {children ?? label ?? String(value)}
        </Base>
      )}
    </ListboxOption> */}
    </>
  );
};

type JsonOptionsProps = {
  options: DropdownFieldOptions;
};
const JsonOptions: Component<JsonOptionsProps> = ({ options }) => {
  return Object.entries(options).map(([key, option]) => (
    <Base key={key} sx={{ display: "contents" }}>
      <SelectOption value={option.value} label={option.label} />
    </Base>
  ));
};

export default DropdownField;
