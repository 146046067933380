import { FlexColumn } from "components/basic/flex";
import Text from "components/basic/text";
import BreadCrumb from "components/composed/breadcrumb";
import { DGProvider } from "components/basic/data-grid";
import { useCallback, useEffect, useState } from "react";
import { DataGridColumns } from "components/basic/data-grid/data-grid-columns.d";
import { Collapse } from "@mantine/core";
import { ManagePropertiesHeader } from "./manage-properties-header";
import { ManagePropertiesTable } from "./manage-properties-table";
import ManagePropertyForm from "./manage-properties-form";
import { CrudProvider, useCrudStore } from "./crud-store";
import { useDataGridStore } from "components/basic/data-grid/data-grid-store";
import { backendPropertiesCrud } from "services/housien/properties";
import Base from "components/basic/base";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { usePagination } from "hooks/use-pagination";
import { withAuthenticationRequired } from "react-oidc-context";
import { agencyContext } from "../agency-pages/agencies-context";
import { LoadingPage } from "pages/other/loading-page";
import { PropertiesService } from "services/requests";
import { withEmotionCache } from "@emotion/react";
import { getPropertyID } from "utils/getPropertyID";
import Button from "components/basic/button";
import Icon from "components/basic/icon";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { squareMetersMask } from "utils/input-masks";
import { appLayoutContext } from "layout/app/layout_context";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";

export type FetchSettings = { pageSize: number; page?: number };

const getRowId = (row: any) => row.id;

type ManagePropertiesProps = {};
const ManageProperties: Component<ManagePropertiesProps> = () => {

  const { useStore } = appLayoutContext;
  const updateNavigationBar = useStore((state) => state.updateNavigationBar);

  const { useStore: useAgencyStore } = agencyContext;
  const { id: agency_id } = useAgencyStore((state) => state.agency);

  useEffect(() => {
    const propertiesPath = `/app/agencies/${agency_id}/properties`;
    const properties_navigate = (
      <SideMenuLink label={"Propriedades"} linkTo={propertiesPath} />
    );
    updateNavigationBar(properties_navigate, 1);
  }, []);

  return (
    <CrudProvider initialState={{ fetchSettings: { pageSize: 10, page: 1 } }}>
      <DGProperties />
    </CrudProvider>
  );
};

const DGProperties: Component = () => {
  const refreshFetch = useCrudStore((state) => state.refreshFetch);
  const setIsFormVisible = useCrudStore((state) => state.setIsFormVisible);
  const setIstableExpanded = useCrudStore((state) => state.setIsTableVisible);

  const { agency_id } = useParams();

  const handleEdit = (e: React.MouseEvent<HTMLElement>, id: string) => {
    // setEditingEntity(selectedRowData);
    e.stopPropagation();
    setIstableExpanded(false);
    setIsFormVisible(true);
    const url = new URL(window.location.href);
    url.searchParams.set("id", id.toString());
    window.history.pushState({}, "", url.href);
  };

  const handleDelete = async (e: React.MouseEvent<HTMLElement>, id: string) => {
    e.stopPropagation();
    if (agency_id) await backendPropertiesCrud(agency_id).exclude({ id });
    // await backendPropertiesCrud(agency_id).exclude({ id });
    refreshFetch();
  };

  const columns: DataGridColumns = {
    __check__: { renderAs: "select", label: "Select", dataType: "boolean" },
    property_title: { renderAs: "raw", label: "Título", dataType: "string" },
    category: { renderAs: "raw", label: "Tipo", dataType: "string" },
    total_area: {
      renderAs: "custom",
      label: "Área",
      dataType: "string",
      renderer: (cellData: string) => (
          <Text sx={{ fontWeight: 700 }}>{cellData ? squareMetersMask(cellData.toString()).masked : ""}</Text>
      ),
    },
    bedrooms: { renderAs: "raw", label: "Quartos", dataType: "number" },
    bathrooms: { renderAs: "raw", label: "Banheiro", dataType: "number" },
    state: { renderAs: "raw", label: "Região", dataType: "string" },
    sale_price: { renderAs: "currency", label: "Venda", dataType: "number" },
    // rental_price: { renderAs: "currency", label: "Aluguel" },
    id: {
      renderAs: "custom",
      label: "",
      dataType: "string",
      renderer: (cellData: string) => (
        <FlexColumn sx={{ gap: 1 }}>
          <Button
            variant="outlined"
            size="micro"
            color="darken.n6"
            onClick={(e) => handleEdit(e, cellData)}
          >
            <Icon as={IconEdit} sx={{ width: "1.2rem" }} /> Editar
          </Button>

          <Button
            variant="outlined"
            size="micro"
            color="darken.n6"
            onClick={(e) => handleDelete(e, cellData)}
          >
            <Icon as={IconTrash} sx={{ width: "1.2rem" }} /> Excluir
          </Button>
        </FlexColumn>
      ),
    },
  };

  return (
    <DGProvider initialState={{ columns, rows: [], getRowId }}>
      <PageContent />
    </DGProvider>
  );
};

const PageContent: Component = () => {
  const fetchId = useCrudStore((state) => state.fetchId);
  const fetchSettings = useCrudStore((state) => state.fetchSettings);
  const isFormVisible = useCrudStore((state) => state.isFormVisible);
  const isTableVisible = useCrudStore((state) => state.isTableVisible);
  const setIsTableVisible = useCrudStore((state) => state.setIsTableVisible);
  const setIsFormVisible = useCrudStore((state) => state.setIsFormVisible);
  const setEditingEntity = useCrudStore((state) => state.setEditingEntity);
  const setStatistics = useCrudStore((state) => state.setStatistics);
  const setIsLoading = useCrudStore((state) => state.setIsLoading);

  const setRows = useDataGridStore((state) => state.setRows);

  const [pagination, setPagination] = usePagination();
  const [numPages, setNumPages] = useState(1);

  const { useStore: useAgencyStore } = agencyContext;
  const { id: agency_id } = useAgencyStore((state) => state.agency);

  useEffect(() => {
    const property_id = getPropertyID();
    if (agency_id && property_id) {
      setIsLoading(true);
      PropertiesService.getProperty(agency_id, property_id)
        .then((property_datas) => {
          setEditingEntity(property_datas);
          setIsTableVisible(false);
          setIsFormVisible(true);
        })
        .catch((error) => {
          console.error("Error fetching agency:", error);
        })
        .finally(() => setIsLoading(true));
    } else {
      setEditingEntity(null);
      backendPropertiesCrud(agency_id)
        .search({ pagination })
        .then((searchResult) => {
          const { data: realEstateProperties, metadata } = searchResult;
          if (realEstateProperties) {
            setRows(Object.values(realEstateProperties) as any, true);
            setNumPages(metadata.pages);
            setStatistics(metadata.statistics);
          }
          setIsLoading(false);
        });
    }
  }, [fetchSettings, fetchId, pagination]);

  return (
    <>
      <Base as={Collapse} in={isFormVisible} sx={{ width: "100%" }}>
        <ManagePropertyForm />
      </Base>

      <Base as={Collapse} in={isTableVisible} sx={{ width: "100%" }}>
        <FlexColumn sx={{ gap: "1rem" }}>
          <Text variant="h3">Propriedades</Text>
          <ManagePropertiesHeader />
          <ManagePropertiesTable numPages={numPages} />
        </FlexColumn>
      </Base>
    </>
  );
};

export default withAuthenticationRequired(ManageProperties);
