export function base64ToFile(base64String: string, fileName: string): File {
  const [header, base64Data] = base64String.split(',');
  const mimeType = header.split(':')[1].split(';')[0]; 

  const binaryData = atob(base64Data);

  const uint8Array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const fileExtension = mimeType.split('/')[1];

  const finalFileName = `${fileName}.${fileExtension}`;
  return new File([uint8Array], finalFileName, { type: mimeType });
}

export function generateRandomFilename(extension: string): string {
  const randomString = Math.random().toString(36).substr(2, 4); 
  return `image${randomString}.${extension}`;
}

export async function convertFileToWebP(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();
    reader.onload = () => {
      img.src = reader.result as string;
    };
    reader.onerror = (error) => {
      reject("Erro ao ler o arquivo: " + error);
    };
    reader.readAsDataURL(file);

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ctx) {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        canvas.toBlob((blob) => {
          if (blob) {
            const webpFilename = generateRandomFilename('webp');
            const webpFile = new File([blob], webpFilename, { type: 'image/webp' });
            resolve(webpFile);
          } else {
            reject("Erro ao converter para WebP.");
          }
        }, 'image/webp');
      } else {
        reject("Erro ao desenhar a imagem no canvas.");
      }
    };
    img.onerror = () => {
      reject("Erro ao carregar a imagem.");
    };
  });
}

export async function fileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}