import React from 'react';
import Base from "components/basic/base";
import TextField from "components/form/custom-fields/text-field";
import {
  useFormStoreFieldValue,
  useFormStoreSetters,
} from "components/form/form-store";
import FormSubmitBehavior from "components/form/form-submit";
import { useStepsContext } from "components/steps-controller/steps-context";
import { useStoreSettersProperty } from "../context";
import { useCallback, useState } from "react";
import { moneyMask } from "utils/input-masks";
import Button from "components/basic/button";
import CheckboxField from "components/form/custom-fields/checkbox-field";
import { backendPropertiesCrud } from "services/housien/properties";
import { LoadingPage } from "pages/other/loading-page";
import { managePropertyFormContext } from "../context";
import { agencyContext } from "pages/authenticated/agency-pages/agencies-context";
import {
  makePropertyBody,
} from "utils/map-values-to-type";
import { useCrudStore } from "../../crud-store";
import type {
  RealEstateProperty,
} from "services/housien/types";
import { toast } from "react-toastify";
import { PropertiesService } from "services/requests";
import { getPropertyID } from "utils/getPropertyID";
import {
  base64ToFile,
  convertFileToWebP,
} from "utils/imagesManipulation";

type BusinessProps = {
  nextStepId: string;
  previousStepId: string;
};
const BusinessForm: React.FC<BusinessProps> = ({
  previousStepId,
  nextStepId,
}) => {
  const setIsFormVisible = useCrudStore((state) => state.setIsFormVisible);
  const setIsTableVisible = useCrudStore((state) => state.setIsTableVisible);
  const refreshFetch = useCrudStore((state) => state.refreshFetch);
  const setEditingEntity = useCrudStore((state) => state.setEditingEntity);
  const editingEntity = useCrudStore((state) => state.editingEntity);

  const { useStore: useAgencyStore } = agencyContext;
  const agency = useAgencyStore((state) => state.agency);

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const { updateValues: updateContextValues, set: setContextValues } =
    useStoreSettersProperty();
  const { useStore } = managePropertyFormContext;
  const values = useStore((state) => state.values);

  const isCondominiumCheck = useFormStoreFieldValue("condominium");
  const { setFieldValue } = useFormStoreSetters();
  if (values.condominium_price && values.condominium_price != "0") {
    setFieldValue("condominium", true);
  }

  const handleCloseForm = useCallback(() => {
    const url = new URL(window.location.href);
    url.search = "";
    window.history.pushState({}, "", url.href);
    setContextValues({ values: {} });
    setEditingEntity(null);
    setCurrentStepId("address");
    setIsFormVisible(false);
    setIsTableVisible(true);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  if (isLoading) return <LoadingPage />;

  return (
    <>
      <Base
        sx={{
          display: "grid",
          gridTemplateColumns:
            "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
          gap: 1,
          "@media(sm-)": {
            background: "red",
          },
          alignItems: "start",
          width: "700px",
        }}
      >
        <TextField
          name="sale_price"
          label="Valor da Venda"
          mask={moneyMask}
          sx={{ gridColumn: "span 12" }}
        />
        <TextField
          name="rental_price"
          label="Valor do Aluguel (mensal)"
          mask={moneyMask}
          sx={{ gridColumn: "span 12" }}
        />
                <TextField
          name="exchange_price"
          label="Valor de Permuta"
          mask={moneyMask}
          sx={{ gridColumn: "span 12" }}
        />
        <CheckboxField name="condominium" label="Condomínio" />
        {isCondominiumCheck && (
          <TextField
            name="condominium_price"
            label="Valor do Condomínio (mensal)"
            mask={moneyMask}
            sx={{ gridColumn: "span 12" }}
          />
        )}
      </Base>
      <Base
        sx={{ width: "100%", display: "flex", justifyContent: "end", gap: 1 }}
      >
        <Button onClick={() => setCurrentStepId(previousStepId)}>Voltar</Button>
        <FormSubmitBehavior
          onSubmit={(state) => {
            const { condominium, ...property_datas } = state.formValues;
            onSubmitForm(property_datas);
          }}
        >
          <Button color="primary.main">
            {editingEntity ? "Salvar e Sair" : "Adicionar"}
          </Button>
        </FormSubmitBehavior>
      </Base>
    </>
  );

  async function onSubmitForm(formValues: Record<string, string>) {
    const property_datas: RealEstateProperty = makePropertyBody({
      ...values,
      ...formValues,
    });

    try {
      updateContextValues(formValues);
      setIsLoading(true);
      const property_id = getPropertyID();
      if (JSON.stringify(editingEntity) != JSON.stringify(property_datas)) {
        const response =
          editingEntity && property_id
            ? await backendPropertiesCrud(agency.id).update({
                id: property_id,
                body: property_datas,
              })
            : await backendPropertiesCrud(agency.id).insert({
                body: property_datas,
              });
        if (values.images) {
          const jsonresponse = Object(response);
          const imageUrl = await PropertiesService.getLinkImageProperty(
            agency.id || "",
            jsonresponse.message
          );
          uploadImages(values.images, imageUrl);
        }
      }
      toast.success("Data saved successfully");
      refreshFetch();
      handleCloseForm();
    } catch (error) {
      toast.error("Could not persist data");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function uploadImages(images: string = "", url: string): Promise<any> {
    const imageFiles: File[] = [];
    for (const base64 of JSON.parse(images)) {
      try {
        const originalFile = base64ToFile(base64, `image_${imageFiles.length}`);
        const webpFile = await convertFileToWebP(originalFile);
        imageFiles.push(webpFile);
      } catch (error) {
        console.error("Erro ao converter ou enviar a imagem:", error);
      }
    }
    const formData = new FormData();
    imageFiles.forEach((imageFile) => {
      formData.append("images", imageFile);
      ``;
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "image/webp",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Falha ao enviar as imagens.");
      }

      const result = await response.json();
      console.log("Imagens enviadas com sucesso:", result);
      return result;
    } catch (error) {
      console.error("Erro ao enviar imagens:", error);
    }
  }
};
export default BusinessForm;
