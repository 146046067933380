import { ReactNode } from "react";
import InputContainer from "components/form/primitives/input/input-container";
import { TextualInputProps } from "components/form/form-input";
import InputFill from "components/form/primitives/input/input-fill";

export type TextInputProps = {
  type?:
    | "text"
    | "number"
    | "email"
    | "password"
    | "date"
    | "time"
    | "datetime"
    | "datetime-local";
  leftAdornment?: ReactNode;
  rightAdornment?: ReactNode;
} & TextualInputProps;

const TextInput: Component<TextInputProps> = ({
  name,
  asTextArea,
  disabled,
  type = "text",
  leftAdornment,
  rightAdornment,
  ...props
}) => {
  const inputProps = {
    type,
    ...props,
  };

  return (
    <InputContainer name={name} {...props}>
      {leftAdornment}
      <InputFill
        name={name}
        asTextArea={asTextArea}
        disabled={disabled}
        {...inputProps}
      />
      {rightAdornment}
    </InputContainer>
  );
};

export default TextInput;
