import Base from "components/basic/base";
import { zIndex } from "themes/light/z-index";

const Popup: ComponentWithChildren = ({ sx, children, ...props }) => {
  return (
    <Base
      sx={{
        position: "absolute",
        top: "calc( 100% + 5px )",
        zIndex: zIndex.popup,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Base>
  );
};

export const PopupContainer: ComponentWithChildren = ({
  sx,
  children,
  ...props
}) => {
  return (
    <Base sx={{ position: "relative", ...sx }} {...props}>
      {children}
    </Base>
  );
};

export default Popup;
