import Button from "components/basic/button";
import Card from "components/basic/card/card";
import { FlexColumn } from "components/basic/flex";
import Text from "components/basic/text";
import BreadCrumb from "components/composed/breadcrumb";
import TextField from "components/form/custom-fields/text-field";
import FormProvider from "components/form/form-store";
import InputAdornment from "components/form/primitives/input-adornment";
import { BiPlus, BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router";

export const ManageAgenciesHeader = () => {
  return (
    <>
      <FlexColumn>
        <Text variant="h3">Agências</Text>
        <BreadCrumb />
      </FlexColumn>
      <SearchBar />
    </>
  );
};

export const SearchBar: Component = () => {
  const navigate = useNavigate();

  return (
    <Card size="micro" sx={{ width: "100%", gap: 1, flexWrap: "wrap" }}>
      <FormProvider>
        <Button
          size="small"
          onClick={() => navigate("/app/register")}
        >
          <BiPlus />
          Criar Agência
        </Button>
        {/* <Button size="small" variant="outlined" sx={{ flexGrow: 1 }}>
          <BiFilter />
          Filters
        </Button> */}
        <TextField
          name="search"
          leftAdornment={
            <InputAdornment
              variant="button"
              buttonVariant="outlined"
              sx={{ color: "text.main" }}
            >
              <BiSearch />
            </InputAdornment>
          }
          sx={{ flexGrow: 1, border: "none" }}
        />
      </FormProvider>
    </Card>
  );
};