import { FlexColumn, FlexFill, FlexRow } from "components/basic/flex";
import Label from "components/form/primitives/field/label";
import { InputMessages } from "components/form/primitives/field/input-message";
import { InfoButton } from "./tooltip-button";
import { useRef } from "react";
import { FormFieldProps } from "components/form/form-field";

export const FieldWrapper: ComponentWithChildren<FormFieldProps> = ({
  name,
  label,
  help,
  children,
  sx,
}) => {
  return (
    <FlexColumn sx={{ ...sx }}>
      <FlexRow sx={{ alignItems: "center" }}>
        {label && <Label name={name}>{label}</Label>}
        <FlexFill />
        {help && <InfoButton text={help ?? ""} />}
      </FlexRow>
      {children}
      <InputMessages name={name} />
    </FlexColumn>
  );
};

export const FieldWrapperInline: ComponentWithChildrenFn<
  React.MutableRefObject<HTMLElement | undefined>,
  FormFieldProps
> = ({ name, label, help, children }) => {
  const labelRef = useRef<HTMLElement>();
  return (
    <FlexRow sx={{ alignItems: "center", gap: 1, cursor: "pointer" }}>
      {typeof children == "function" && children(labelRef)}
      <Label
        name={name}
        sx={{ cursor: "inherit" }}
        onClick={() => labelRef?.current?.click()}
      >
        {label}
      </Label>
    </FlexRow>
  );
};
