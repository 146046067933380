export type MaskFunction = (value: string) => {
  unmasked: string;
  masked: string;
};

export const ccvMask: MaskFunction = (value) => {
  const clamped = value.replaceAll(/[^\d]/g, "").slice(0, 3);
  return { unmasked: clamped, masked: clamped };
};

export const cpfMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "").slice(0, 11);
  const masked = unmasked
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+$/, "$1");
  return { unmasked, masked };
};

export const cnpjMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+$/, "$1");
  return { unmasked, masked };
};

export const phoneMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+$/, "$1");
  return { unmasked, masked };
};

export const phoneDDIMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{2})(\d)/, "+$1 $2")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+$/, "$1");
  return { unmasked, masked };
};

export const cepMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{3})\d+$/, "$1");
  return { unmasked, masked };
};

export const pisMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{5})(\d)/, "$1.$2")
    .replace(/(\d{5}\.)(\d{2})(\d)/, "$1$2-$3")
    .replace(/(-\d)\d+$/, "$1");
  return { unmasked, masked };
};

export const moneyMask: MaskFunction = (value) => {
  const unmasked = (Number(value.replace(/\D+/g, "")) / 100).toFixed(2); // Valor numérico com separador decimal
  const masked =
    "R$" +
    new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(unmasked));
  return { unmasked, masked }; // Retorna o valor desmascarado com separador decimal
};

export const squareMetersMask: MaskFunction = (value) => {
  const unmasked = (Number(value.replace(/\D+/g, "")) / 100).toFixed(2); // Valor numérico com separador decimal
  const masked =
    new Intl.NumberFormat("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(unmasked)) + " m²";
  return { unmasked, masked }; // Retorna o valor desmascarado com separador decimal
};


export const dateMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\/\d{2})(\d)/, "$1/$2")
    .replace(/(\/\d{4})\d+$/, "$1");
  return { unmasked, masked };
};

export const dateMonthYearMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\/\d{2})\d+$/, "$1");
  return { unmasked, masked };
};

export const dateWithDashesMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{2})(\d)/, "$1-$2")
    .replace(/(-\d{2})(\d)/, "$1-$2")
    .replace(/(-\d{4})\d+$/, "$1");
  return { unmasked, masked };
};

export const hourMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{2})(\d)/, "$1:$2")
    .replace(/(:\d{2})\d+$/, "$1");
  return { unmasked, masked };
};

export const creditCardNumberMask: MaskFunction = (value) => {
  const unmasked = value.replace(/\D+/g, "");
  const masked = unmasked
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)+$/, "$1");
  return { unmasked, masked };
};
