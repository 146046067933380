import { typeDefinitionRealEstateProperty } from "services/housien/constants";
import { PropertyFormType, RealEstateProperty } from "services/housien/types";

export function mapFormValuesToType<T extends Record<string, any>>(
  formValues: { [key: string]: string | undefined },
  typeDefinition: T
): T {
  const parsedObject: Partial<T> = {};

  for (const key in formValues) {
    if (formValues.hasOwnProperty(key)) {
      const value = formValues[key];

      const expectedType = typeof typeDefinition[key as keyof T];

      if (!value) {
        if (expectedType === "object")
          parsedObject[key as keyof T] = [] as T[keyof T];
        else parsedObject[key as keyof T] = undefined as T[keyof T];
        continue;
      }

      switch (expectedType) {
        case "bigint":
          parsedObject[key as keyof T] = (
            isNaN(Number(value)) ? undefined : Number(value)
          ) as T[keyof T];
          break;

        case "number":
          parsedObject[key as keyof T] = (
            isNaN(parseFloat(value))
              ? undefined
              : parseFloat(parseFloat(value).toFixed(2))
          ) as T[keyof T];
          break;

        case "boolean":
          parsedObject[key as keyof T] = (value.toLowerCase() ===
            "true") as T[keyof T];
          break;

        case "object":
          try {
            if (Array.isArray(typeDefinition[key as keyof T])) {
              parsedObject[key as keyof T] = value
                .split(",")
                .map((v) => v.trim()) as T[keyof T];
            } else {
              parsedObject[key as keyof T] = JSON.parse(value) as T[keyof T];
            }
          } catch {
            parsedObject[key as keyof T] = [] as T[keyof T];
          }
          break;

        case "string":
        default:
          parsedObject[key as keyof T] = value as T[keyof T];
      }
    }
  }

  return parsedObject as T;
}

export function makePropertyBody(values: Record<string, string>) {
  const property_datas: RealEstateProperty = mapFormValuesToType(
    values,
    typeDefinitionRealEstateProperty
  ) as RealEstateProperty;

  property_datas.price_per_m2 =
    property_datas.sale_price && property_datas.total_area
      ? property_datas.sale_price / property_datas.total_area
      : 0;
  property_datas.rental_price_per_m2 =
    property_datas.rental_price && property_datas.total_area
      ? property_datas.rental_price / property_datas.total_area
      : 0;

  return property_datas;
}
