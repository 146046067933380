import createLocalZustand from "context/local-zustand";
import { produce } from "immer";
import { useShallow } from "zustand/react/shallow";

interface FastContextType {
  values: Record<string, any>;
  set: HandleChangeStore;
  get: () => FastContextType;
  setValues: HandleChangeValues;
  updateValues: HandleChangeValues;
}

export type HandleChangeStore = (
  partial:
    | FastContextType
    | Partial<FastContextType>
    | ((
        state: FastContextType
      ) => FastContextType | Partial<FastContextType>),
  replace?: boolean | undefined
) => void;

export type HandleChangeValues = (value: Record<string, any>) => void;

export const fastContext = createLocalZustand<FastContextType>(
  (initializer) => (set, get) => ({
    values: { ...initializer.values },

    set: set,
    get: get,

    setValues: (values) =>
      set((state) => {
        const newState = produce(state, (draft) => {
          draft.values = values;
        });
        return newState;
      }),

    updateValues: (newValues) =>
      set((state) => {
        const { values } = get();
        const newState = produce(state, (draft) => {
          draft.values = { ...values, ...newValues };
        });
        return newState;
      }),
  })
);

const { useStore } = fastContext;

export const useStoreSetters = (): Pick<
  FastContextType,
  "set" | "setValues" | "updateValues"
> => {
  return useStore(
    useShallow((state) => ({
      set: state.set,
      setValues: state.setValues,
      updateValues: state.updateValues,
    }))
  ) as {
    set: HandleChangeStore;
    setValues: HandleChangeValues;
    updateValues: HandleChangeValues;
  };
};
