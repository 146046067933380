import { generateCrudFunctions } from "../json-server/json-server-crud-generator";
import { RealEstateProperty } from "./types";
import { HOUSIEN_BACKEND_URL } from "./constants";

export const backendPropertiesCrud = (agency_id?: string) => {
  return generateCrudFunctions<RealEstateProperty>( //TODO atualizar para modelo real quando tiver backend terminado
    HOUSIEN_BACKEND_URL,
    "agencies",
    "properties",
    agency_id
  );
};
