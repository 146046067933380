import { FlexRow } from "components/basic/flex";
import { FlattenObjectKeys } from "types/flatten-object-keys";
import { fieldSizes } from "themes/light/layout";
import { forms } from "themes/light/forms";
import { useFormStoreFieldError, useFormStoreFieldState } from "components/form/form-store";

type ThemeSizes = FlattenObjectKeys<typeof fieldSizes>;
type ThemeVariants = FlattenObjectKeys<typeof forms.inputContainer>;

type InputContainerProps = {
  name: string;
  variant?: ThemeVariants;
  size?: ThemeSizes;
  status?: "default" | "error" | "success";
};

const InputContainer: ComponentWithChildren<InputContainerProps> = ({
  name,
  children,
  variant = "outlined",
  size = "small",
  status,
  sx,
  ...props
}) => {
  const fieldState = useFormStoreFieldState(name);
  const fieldError = useFormStoreFieldError(name);
  return (
    <FlexRow
      sx={{
        variant: `forms.inputContainer.variants.${variant}`,
        height: `${size}`,
        borderRadius: 2,
        display: "flex",
        overflow: "hidden",
        flexShrink: 0,
        flexGrow: 1,
        width: "100%",
        maxWidth: "100%",
        transition: "border-color 0.3s",
        ...(fieldState?.isFocused && {
          borderWidth: "1.5px",
          borderColor: "primary.main",
          color: "primary.dark2",
        }),
        ...(status === "success" && {
          borderColor: "success.main",
          color: "success.dark1",
        }),
        ...(fieldError !== undefined && {
          borderColor: "error.main",
          color: "error.dark1",
        }),
        ...sx,
      }}
      {...props}
    >
      {children}
    </FlexRow>
  );
};

export default InputContainer;
