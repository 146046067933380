import InputFill from "components/form/primitives/input/input-fill";
import { useState } from "react";
import {
  Combobox as MantineCombobox,
  ComboboxEmpty,
  useCombobox,
  ComboboxTarget,
  ComboboxDropdown,
  ComboboxOptions,
  ComboboxOption,
} from "@mantine/core";
import Base from "components/basic/base";
import { ForwardRef } from "components/basic/forward-ref";
import InputContainer from "components/form/primitives/input/input-container";
import InputAdornment from "../primitives/adornment";
import { useFormStoreFieldData, useFormStoreSetters } from "../form-store";
import { FormInputProps } from "../form-input";
import { BiChevronDown } from "react-icons/bi";
import Card from "components/basic/card/card";

export type ComboboxProps = {
  options: string[];
  mode?: "combobox" | "dropdown";
  nullMessage?: string;
} & FormInputProps;

const Combobox: ComponentWithChildren<ComboboxProps> = ({
  name,
  mode = "combobox",
  nullMessage = "Nothing found",
  options,
  disabled = false,
  placeholder,
  onChange,
  children,
  sx,
  ...props
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const fieldData = useFormStoreFieldData(name);
  const { value, state = {} } = fieldData;

  const setters = useFormStoreSetters();
  const { setFieldValue } = setters;

  const [search, setSearch] = useState(value ?? "");

  const shouldFilterOptions = options.every((item) => item !== search);
  const filteredOptions = shouldFilterOptions
    ? options.filter((item) =>
        item.toLowerCase().includes(search.toLowerCase().trim())
      )
    : options;

  const optionsToRender = filteredOptions.map((item) => (
    <ComboboxOption value={item} key={item}>
      {item}
    </ComboboxOption>
  ));

  return (
    <MantineCombobox
      disabled={disabled}
      store={combobox}
      onOptionSubmit={(value) => {
        setFieldValue(name, value);
        setSearch(value);
        combobox.closeDropdown();
      }}
    >
      <ComboboxTarget>
        <ForwardRef>
          <InputContainer name={name} onClick={() => combobox.openDropdown()}>
            <InputFill
              autoComplete={false}
              name={name}
              disabled={disabled}
              readOnly={mode === "dropdown"}
              onClick={() => combobox.openDropdown()}
              onFocus={() => combobox.openDropdown()}
              onBlur={() => {
                combobox.closeDropdown();
                setSearch(value ?? "");
              }}
              placeholder={placeholder ?? "Selecione"}
              value={search}
              onChange={(event) => {
                combobox.updateSelectedOptionIndex();
                setSearch(event.currentTarget.value);
              }}
              sx={{
                ...(!disabled && {
                  cursor: mode === "dropdown" ? "pointer" : "initial",
                }),
              }}
            />
            {
              <InputAdornment variant="none" leftBorder>
                <BiChevronDown />
              </InputAdornment>
            }
          </InputContainer>
        </ForwardRef>
      </ComboboxTarget>

      <Base as={ComboboxDropdown}>
        <ComboboxOptions
          style={{
            left: 0,
            top: "0",
            position: "absolute",
            borderRadius: "4px",
            boxShadow: "0 0 8px rgba(0,0,0,0.1)",
            listStyle: "none",
            minWidth: "100%",
            overflowY: "auto",
            maxHeight: "155px",
            zIndex: 1,
            background: "#eef2f6",
            border: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          {optionsToRender.length > 0 ? (
            optionsToRender
          ) : (
            <ComboboxEmpty>{nullMessage}</ComboboxEmpty>
          )}
        </ComboboxOptions>
      </Base>
    </MantineCombobox>
  );
};

export default Combobox;
