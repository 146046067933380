import Card from "./card/card";
import Text from "components/basic/text";

export const DefaultIcon: Component = ({ text, fontSize = 50 }) => {
    return (
      <Card
        size="unset"
        sx={{
          background: "primary.main",
          width: fontSize,
          height: fontSize,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          sx={{
            fontSize: `${fontSize / 2}px`,
            fontWeight: "900",
            color: "white",
          }}
        >
          {text.toUpperCase()}
        </Text>
      </Card>
    );
  };