import { fastContext } from "context/fast-context";

export interface EarlyAdopterValues {
  other_ocupation?: string;
  ocupation?: string;
  city?: string;
  state?: string;
  important_function?: string;
  others_important_function?: string;
  amount_properties?: string;
  amount_employees?: string;
  search_improve?: string;
}

export const earlyAdopterFormContext = fastContext;