import Base from "components/basic/base";
import { useFormStoreFieldError, useFormStoreFieldState } from "components/form/form-store";

type Props = {
  name: string;
  label?: string;
};

const Label: ComponentWithChildren<Props> = ({
  name,
  label,
  children,
  sx,
  ...props
}) => {
  const fieldState = useFormStoreFieldState(name);
  const fieldError = useFormStoreFieldError(name);

  return (
    <Base
      as="label"
      htmlFor={name}
      sx={{
        whiteSpace: "nowrap",
        variant: "text.form.labels",
        ...(fieldState.isFocused && {
          color: "primary.dark1",
          fontWeight: 600,
        }),
        ...(fieldError !== undefined && { color: "error.main" }),
        ...sx,
      }}
      {...props}
    >
      {children ?? label}
    </Base>
  );
};

export default Label;
