import StepContent from "components/steps-controller/step-content";
import { managePropertyFormContext } from "../context";
import FormProvider from "components/form/form-store";
import PropertyForm from "./property";
import AddressForm from "./address";
import ImagesForm from "./images";
import BusinessForm from "./business";

export const ManagePropertyContents = () => {
  const { useStore } = managePropertyFormContext;
  const values = useStore((state) => state.values);
  
  return (
    <>
      <StepContent stepId="address">
        <FormProvider
          required={[
            "postal_code",
            "street",
            "number",
            "neighborhood",
            "city",
            "state",
          ]}
          initialValues={values}
        >
          <AddressForm previousStepId="property" nextStepId="property" />
        </FormProvider>
      </StepContent>
      <StepContent stepId="property">
        <FormProvider initialValues={values}>
          <PropertyForm previousStepId="address" nextStepId="images" />
        </FormProvider>
      </StepContent>
      <StepContent stepId="images">
        <FormProvider initialValues={values}>
          <ImagesForm previousStepId="property" nextStepId="business" />
        </FormProvider>
      </StepContent>
      <StepContent stepId="business">
        <FormProvider initialValues={values}>
          <BusinessForm previousStepId="images" nextStepId="property" />
        </FormProvider>
      </StepContent>
    </>
  );
};

export default ManagePropertyContents;
