import { ThemeUIProvider } from "theme-ui";
import { lightTheme } from "themes/light/theme";
import { Suspense, lazy, useEffect, useState } from "react";
import AppLayout, {
  agencyLinkItems,
  mainAppLinkItems,
  propertyLinkItems,
} from "layout/app";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@mantine/core/styles.css";
import ManageAgencies from "pages/authenticated/manage-agencies";
import ConfigAgencies from "pages/authenticated/config-real-estate";
import ManageProperties from "pages/authenticated/manage-properties";
import { MantineProvider } from "@mantine/core";
import RealStateSettings from "pages/authenticated/agency-pages/real-estate-settings";
import { LoadingPage } from "pages/other/loading-page";
import TestPage from "pages/other/test-page";
import CreateRealState from "pages/authenticated/create-real-estate";

import NotFoundPage from "pages/unauthenticated/not-found";
import AuthenticationCallback from "pages/authenticated/callback";
import AuthenticationProvider from "context/authentication";
import { useAuth } from "react-oidc-context";
import EarlyAdopterPage from "pages/authenticated/early-adopter";
import ServiceTermsVersion1 from "pages/unauthenticated/service_terms/Version1";
import { agencyContext } from "pages/authenticated/agency-pages/agencies-context";
import OverviewPage from "pages/authenticated/agency-pages/overview";
import { AgenciesService, PropertiesService } from "services/requests";
import { appLayoutContext } from "layout/app/layout_context";
import { propertyContext } from "pages/authenticated/property-pages/property-context";
import {
  loggedAgencyRoutes,
  loggedPropertyRoutes,
  loggedRoutesList,
} from "services/housien/constants";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";
import { DefaultIcon } from "components/basic/icon-default";
import PropertyOverviewPage from "pages/authenticated/property-pages/overview";
import PropertyBusinessPage from "pages/authenticated/property-pages/business";
import PropertyHistoryPage from "pages/authenticated/property-pages/history";
import PropertySimilarPage from "pages/authenticated/property-pages/similar";

const ZustandTest = lazy(() => import("pages/other/zustand-test"));
const DashboardPage = lazy(
  () => import("pages/authenticated/agency-pages/dashboard")
);
const Register = lazy(() => import("pages/authenticated/register-agency"));
const ComponentsPage = lazy(
  () => import("pages/documentation/documentation-page")
);
const Landing = lazy(() => import("pages/unauthenticated/landing"));
const Auth = lazy(() => import("pages/unauthenticated/auth/auth"));

function App() {
  const { Provider: AppLayoutContextProvider } = appLayoutContext;
  return (
    <MantineProvider>
      <ThemeUIProvider theme={lightTheme}>
        <AuthenticationProvider>
          <AppLayoutContextProvider initialState={mainAppLinkItems}>
            <BrowserRouter>
              <ToastContainer position="top-right" autoClose={5000} />
              <Suspense fallback={<LoadingPage />}>
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/auth" element={<Auth />} />
                  <Route path="app/*" element={<LoggedRoutes />} />

                  <Route path="/earlyadopter" element={<EarlyAdopterPage />} />
                  <Route
                    path="/serviceterms/*"
                    element={<ServiceTermsRoutes />}
                  />
                  <Route
                    path="/authentication/callback"
                    element={<AuthenticationCallback />}
                  />
                  <Route path="*" element={<Navigate to="app" />} />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </AppLayoutContextProvider>
        </AuthenticationProvider>
      </ThemeUIProvider>
    </MantineProvider>
  );
}

export const LoggedRoutes = () => {
  const { useStore } = appLayoutContext;
  const set = useStore((state) => state.set);

  const location = useLocation();
  useEffect(() => {
    if (loggedRoutesList.includes(location.pathname)) {
      set((state) => ({
        ...state,
        linkItems: { ...mainAppLinkItems },
        agency: undefined,
        property: undefined,
      }));
    }
  }, [location]);

  return (
    <AppLayout>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="documentation/*" element={<ComponentsPage />} />
          <Route path="real-states" element={<ManageAgencies />} />
          <Route path="real-states/create" element={<CreateRealState />} />
          <Route path="real-estate" element={<ConfigAgencies />} />
          <Route path="real-estate/properties" element={<ManageProperties />} />
          <Route path="real-estate/settings" element={<RealStateSettings />} />
          <Route path="register" element={<Register />} />
          <Route path="zustand" element={<ZustandTest />} />
          <Route path="crud" element={<ManageProperties />} />
          <Route path="loading" element={<LoadingPage />} />
          <Route path="test" element={<TestPage />} />
          <Route path="test-zustand" element={<ZustandTest />} />
          <Route path="agencies" element={<ManageAgencies />} />
          <Route
            path="agencies/:agency_id/*"
            element={<LoggedAgencyRoutes />}
          />
          <Route path="*" element={<Navigate to="/app/agencies" />} />
        </Routes>
      </Suspense>
    </AppLayout>
  );
};

export type RouteParams = {
  agency_id: string;
  property_id: string;
};

export const LoggedAgencyRoutes = () => {
  const { agency_id } = useParams<RouteParams>();
  const [agency, setAgency] = useState<Record<string, any> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const { useStore } = appLayoutContext;
  const set = useStore((state) => state.set);
  const updateNavigationBar = useStore((state) => state.updateNavigationBar);

  const location = useLocation();
  useEffect(() => {
    if (agency_id) {
      AgenciesService.getAgency(agency_id)
        .then((agency) => {
          setAgency(agency);

          const agencyPath = `/app/agencies/${agency.id}`;
          const agency_navigate = (
            <SideMenuLink
              label={agency.name}
              linkTo={agencyPath}
              icon={<DefaultIcon text={agency.name[0]} fontSize={24} />}
            />
          );
          updateNavigationBar(agency_navigate, 0);
        })
        .catch((error) => {
          console.error("Error fetching agency:", error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [agency_id, set]);

  useEffect(() => {
    if (
      agency_id &&
      agency &&
      loggedAgencyRoutes(agency_id).includes(location.pathname)
    ) {
      const linkItems = agencyLinkItems(agency);
      set((state) => ({
        ...state,
        linkItems: { ...linkItems },
      }));
    }
  }, [agency, location]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const { Provider: AgencyContextProvider } = agencyContext;
  if (agency) {
    return (
      <AgencyContextProvider initialState={agency}>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route path="" element={<OverviewPage />} />
            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="properties" element={<ManageProperties />} />
            <Route
              path="properties/:property_id/*"
              element={<LoggedPropertyRoutes />}
            />
            <Route path="settings" element={<RealStateSettings />} />
            <Route path="crud" element={<ManageProperties />} />
            <Route path="*" element={<Navigate to="properties" />} />
          </Routes>
        </Suspense>
      </AgencyContextProvider>
    );
  }

  return <NotFoundPage redirect="/app/agencies" />;
};

export const LoggedPropertyRoutes = () => {
  const { agency_id, property_id } = useParams<RouteParams>();
  const [property, setproperty] = useState<Record<string, any> | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  const { useStore } = appLayoutContext;
  const set = useStore((state) => state.set);
  const updateNavigationBar = useStore((state) => state.updateNavigationBar);

  const location = useLocation();
  useEffect(() => {
    if (agency_id && property_id) {
      PropertiesService.getProperty(agency_id, property_id)
        .then((property) => {
          setproperty(property);

          const propertiesPath = `/app/agencies/${agency_id}/properties`;
          const properties_navigate = (
            <SideMenuLink label={"Propriedades"} linkTo={propertiesPath} />
          );
          updateNavigationBar(properties_navigate, 1);
          const propertyPath = `/app/agencies/${agency_id}/properties/${property.id}`;
          const property_navigate = (
            <SideMenuLink
              label={property.property_title}
              linkTo={propertyPath}
              icon={
                <DefaultIcon text={property.property_title[0]} fontSize={24} />
              }
            />
          );
          updateNavigationBar(property_navigate, 2);
        })
        .catch((error) => {
          console.error("Error fetching property:", error);
        })
        .finally(() => setIsLoading(false));
    }
  }, [property_id, set]);

  useEffect(() => {
    if (
      agency_id &&
      property_id &&
      property &&
      loggedPropertyRoutes(agency_id, property_id).includes(location.pathname)
    ) {
      const linkItems = propertyLinkItems(property);
      set((state) => ({
        ...state,
        linkItems: { ...linkItems },
      }));
    }
  }, [property, location]);

  if (isLoading) {
    return <LoadingPage />;
  }

  const { Provider: PropertyContextProvider } = propertyContext;
  if (property) {
    return (
      <PropertyContextProvider initialState={{values: {property}}}>
        <Suspense fallback={<LoadingPage />}>
          <Routes>
            <Route path="" element={<PropertyOverviewPage />} />
            <Route path="business" element={<PropertyBusinessPage />} />
            <Route path="history" element={<PropertyHistoryPage />} />
            <Route path="similar" element={<PropertySimilarPage />} />
            {/* <Route path="edit" element={<>Edit</>} /> */}
            <Route path="*" element={<Navigate to="" />} />
          </Routes>
        </Suspense>
      </PropertyContextProvider>
    );
  }

  return <NotFoundPage redirect={`/app/agencies/${agency_id}`} />;
};

export const ServiceTermsRoutes = () => {
  const versions = [<ServiceTermsVersion1 />];
  return (
    <Routes>
      {versions.map((version, index) => (
        <Route key={index + 1} path={`v${index + 1}`} element={version} />
      ))}
      <Route path={"*"} element={versions[versions.length - 1]} />
    </Routes>
  );
};

export default App;
