/** @jsxImportSource theme-ui */
import Base from "components/basic/base";
import { shade, tint } from "@theme-ui/color";
import { fieldSizes } from "themes/light/layout";
import { FlattenObjectKeys } from "types/flatten-object-keys";
import Link from "./link";

type FlatThemeFieldSizes = FlattenObjectKeys<typeof fieldSizes>;

export type ButtonProps = {
  variant?:
    | "solid"
    | "outlined"
    | "ghost"
    | "ghost-text"
    | "link"
    | "soft"
    | "none";
  size?: FlatThemeFieldSizes;
  color?: string;
  disabled?: boolean;
  loading?: boolean;
  disableSize?: boolean;
  disableClickAnimation?: boolean;
  preventDefault?: boolean;
  fixHover?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  fullWidth?: boolean;
  addOutline?: boolean;
  disableEffects?: boolean;
};

const Button: ComponentWithChildren<ButtonProps> = ({
  children,
  variant = "solid",
  size = "small",
  color = "primary.main",
  disabled,
  disableSize = false,
  disableClickAnimation = false,
  preventDefault = false,
  fullWidth = false,
  loading,
  fixHover: forceHover,
  addOutline = false,
  disableEffects = false,
  onClick,
  sx,
  ...props
}) => {
  // If preventDefault, wrap the handler preventing it
  let handleClick = onClick;
  if (preventDefault)
    handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.preventDefault();
      onClick?.(event);
    };

  return (
    <Base
      as="button"
      className={forceHover && "force-hover"}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: 2,
        flexGrow: 0,
        //transition: "background .2s, border .2s",
        height: !disableSize && `${size}`,
        px: 2,
        background: "none",
        color: "inherit",
        border: "none",
        outlineColor: "primary.dark1",
        textDecoration: "none",
        transition: "all 0.1s",
        flexShrink: 0,
        width: fullWidth ? "100%" : "auto",
        ...(!disableClickAnimation &&
          !disableEffects && {
            "&:active": { transform: "translateY(2px)" },
          }),
        ...(variant === "soft" && {
          background: tint(color, 0.8),
          color: shade(color, 0.3),
          "&:hover, &.force-hover": {
            background: color,
            color: "white",
          },
        }),
        ...(variant === "solid" && {
          background: color,
          color: "white",
          border: "none",
          "&:hover, &.force-hover": {
            background: shade(color, 0.15),
          },
        }),
        ...(variant === "outlined" && {
          border: "solid 1px transparent",
          borderColor: color,
          color: color,
          background: "none",
          backgroundBlendMode: "hard-light",
          "&:hover, &.force-hover": {
            background: tint(color, 0.95),
            borderColor: color,
          },
        }),
        ...(variant === "ghost" && {
          border: "none",
          background: "none",
          color: color,
          "&:hover, &.force-hover": { background: tint(color, 0.95) },
        }),
        ...(variant === "ghost-text" && {
          border: "none",
          background: "none",
          color: "text.main",
          "&:hover, &.force-hover": {
            color: shade(color, 0.3),
            background: tint(color, 0.95),
          },
        }),
        ...(variant === "link" && {
          background: "none",
          border: "none",
          color: color,
          "&:hover, &.force-hover": {
            textDecoration: "underline",
          },
        }),
        ...((disabled || loading) && {
          opacity: 0.5,
          cursor: "not-allowed",
          filter: "grayscale(.5)",
        }),
        ...(addOutline && {
          border: "1px solid red",
          borderColor: color,
          //borderColor: "rgba(0,0,0,0.2)",
        }),
        ...(disableEffects && {
          "&:hover, &.force-hover": {},
          "&:hover": {},
          cursor: "default",
        }),
        ...sx,
      }}
      {...props}
      onClick={!disabled ? handleClick : () => {}}
    >
      {children}
    </Base>
  );
};

export type ButtonLinkProps = ButtonProps & { href: string };
export const ButtonLink: ComponentWithChildren<ButtonLinkProps> = ({
  href,
  children,
  ...props
}) => {
  return (
    <Link href={href} onlyBehavior>
      <Button {...props}>{children}</Button>
    </Link>
  );
};

export default Button;
