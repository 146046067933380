import { FormFieldProps } from "../form-field";
import Dropdown, { DropdownProps } from "../inputs/dropdown";
import { FieldWrapper } from "../primitives/field/field-wrapper";

export type DropdownFieldProps = DropdownProps & FormFieldProps;

const DropdownField: Component<DropdownFieldProps> = ({
  name,
  label,
  help,
  disabled,
  validation,
  validationMoment,
  sx,
  ...props
}) => {
  return (
    <FieldWrapper name={name} label={label} help={help} sx={sx}>
      <Dropdown
        {...{ name, disabled, validation, validationMoment, ...props }}
      />
    </FieldWrapper>
  );
};

export default DropdownField;
