import { fastContext } from "context/fast-context";
import createLocalZustand from "context/local-zustand";
import { produce } from "immer";
import { PropertyFormType, RealEstateProperty } from "services/housien/types";
import { useShallow } from "zustand/react/shallow";

interface PropertyContextType {
  values: PropertyFormType;
  set: HandleChangeStore;
  get: () => PropertyContextType;
  setValues: HandleChangeValues;
  updateValues: HandleChangeValues;
}

export type HandleChangeStore = (
  partial:
    | PropertyContextType
    | Partial<PropertyContextType>
    | ((
        state: PropertyContextType
      ) => PropertyContextType | Partial<PropertyContextType>),
  replace?: boolean | undefined
) => void;

export type HandleChangeValues = (value: PropertyFormType) => void;

export const managePropertyFormContext = createLocalZustand<PropertyContextType>(
  (initializer) => (set, get) => ({
    values: { ...initializer.values },

    set: set,
    get: get,

    setValues: (values) =>
      set((state) => {
        const newState = produce(state, (draft) => {
          draft.values = values;
        });
        return newState;
      }),

    updateValues: (newValues) =>
      set((state) => {
        const { values } = get();
        const newState = produce(state, (draft) => {
          draft.values = { ...values, ...newValues };
        });
        return newState;
      }),
  })
);

const { useStore } = managePropertyFormContext;

export const useStoreSettersProperty = (): Pick<
  PropertyContextType,
  "set" | "setValues" | "updateValues"
> => {
  return useStore(
    useShallow((state) => ({
      set: state.set,
      setValues: state.setValues,
      updateValues: state.updateValues,
    }))
  ) as {
    set: HandleChangeStore;
    setValues: HandleChangeValues;
    updateValues: HandleChangeValues;
  };
};

export const propertieContext = fastContext; 