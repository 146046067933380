import { CardProps, CardWithSections } from "./card";
import { CardSection } from "./card-section";
import Base from "../base";
import { ThemeUIStyleObject } from "theme-ui";
import { zIndex } from "themes/light/z-index";

type FancyCardProps = CardProps & {
  color?:
    | "primary.main"
    | "secondary.main"
    | "red.main"
    | "green.main"
    | "blue.main"
    | "purple.main"
    | "orange.main"
    | "pink.main"
    | "yellow.main"
    | string;
  contentSx?: ThemeUIStyleObject;
  backgroundSx?: ThemeUIStyleObject;
  stencilScale?: number;
  stencilTone?: "darken" | "lighten";
};
const FancyCard: ComponentWithChildren<FancyCardProps> = ({
  direction,
  color = "primary.main",
  stencilScale = 1,
  stencilTone = "darken",
  children,
  sx,
  contentSx,
  backgroundSx,
  ...props
}) => {
  return (
    <CardWithSections
      sx={{
        position: "relative",
        zIndex: zIndex.behindContent,
        background: color,
        overflow: "clip",
        ...sx,
      }}
      {...props}
    >
      <Base
        sx={{
          zIndex: zIndex.behindContent,
          position: "absolute",
          width: "100%",
          height: "100%",
          ":before, :after": {
            content: "''",
            position: "absolute",
            width: "210px",
            height: "210px",
            background:
              stencilTone === "darken"
                ? "rgba(0,0,0,0.1)"
                : "rgba(255,255,255,0.1)",
            borderRadius: "400px",
            transform: `scale(${stencilScale})`,
            transformOrigin: "",
          },
          ":before": {
            top: "-155px",
            right: "-70px",
          },
          ":after": {
            top: "-105px",
            right: "-140px",
          },
          ...backgroundSx,
        }}
      />
      <CardSection
        direction={direction}
        sx={{
          zIndex: zIndex.default,
          ...contentSx,
        }}
      >
        {children}
      </CardSection>
    </CardWithSections>
  );
};

export default FancyCard;
