import { FlexFill, FlexRow } from "components/basic/flex";
import IconButton from "components/basic/icon-button";
import Icon from "components/basic/icon";
import { IconCheck, IconX } from "@tabler/icons-react";
import Text from "components/basic/text";
import { useFormStore } from "components/form/form-store";
import { useFiltersStore } from "./context/filters-store";

type FilterHeaderProps = {
  title?: string;
  columnId: string;
  onClose: () => void;
};
export const FilterHeader: ComponentWithChildren<FilterHeaderProps> = ({
  title,
  columnId,
  onClose,
  children,
}) => {
  const formValues = useFormStore((state) => state.formValues);
  const removeFilters = useFiltersStore((state) => state.removeFilters);

  return (
    <FlexRow
      sx={{
        gap: 1,
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Text
        sx={{
          fontWeight: "bold",
          display: "flex",
          alignItems: "flex-start",
          gap: 1,
        }}
      >
        {children ?? title}
      </Text>
      <FlexFill />
      <IconButton
        size="micro"
        color="error.main"
        variant="outlined"
        onClick={() => {
          removeFilters(columnId);
          onClose();
        }}
      >
        <Icon as={IconX} />
      </IconButton>
      <IconButton
        size="micro"
        variant="outlined"
        onClick={() => {
          console.log(formValues);
          onClose();
        }}
      >
        <Icon as={IconCheck} />
      </IconButton>
    </FlexRow>
  );
};
