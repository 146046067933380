import Base from "components/basic/base";
import { useRef } from "react";
import {
  DataGridColumnData,
  DataGridColumnRenderTypes,
  DataGridRowData,
} from "../data-grid-columns";
import { DGCellRaw } from "./raw";
import { DGCellBoolean } from "./boolean";
import { DGCellLink } from "./link";
import { DGCellPerson } from "./person";
import { DGCellCurrency } from "./currency";
import { DGCellCustom } from "./custom";
import { DGCellSelect } from "./select";

export interface CellProps {
  rowData?: DataGridRowData;
  columnId: string;
  columnData: DataGridColumnData;
  index?: number;
}
export const DataGridCell: ComponentWithChildren<CellProps> = ({
  columnId,
  columnData,
  index,
  children,
  sx,
}) => {
  let gridColumn;
  if (typeof index === "number") gridColumn = `${index + 1} / ${index + 2}`;

  const hasColumnDivider = true;

  const containerRef = useRef<HTMLElement>(null);
  return (
    <Base
      ref={containerRef}
      sx={{
        display: "flex",
        justifyContent: columnData.align ?? "flex-start",
        alignItems: "center",
        position: "inherit",
        top: "inherit",
        left: "inherit",
        right: "inherit",
        bottom: "inherit",
        background: "inherit",
        gridColumn,
        borderStyle: "solid",
        borderColor: "neutral.n2",
        borderWidth: `0  0 1px ${index !== 0 && hasColumnDivider ? 1 : 0}px`,
        borderLeftColor: "neutral.n1",
        py: 2,
        px: 3,
        whiteSpace: "nowrap",
        textWrap: "nowrap",
        textOverflow: "ellipsis",
        overflow: "clip",
        "&:nth-of-type(1)": { borderLeftWidth: 0 },
        ...sx,
      }}
    >
      {children}
    </Base>
  );
};

export const dataGridCellTypes: Partial<
  Record<DataGridColumnRenderTypes, Component<any>>
> = {
  select: DGCellSelect,
  raw: DGCellRaw,
  boolean: DGCellBoolean,
  link: DGCellLink,
  person: DGCellPerson,
  currency: DGCellCurrency,
  //date: DGCellDate,
  custom: DGCellCustom,
};

export function getDataGridCellByType(
  renderAs: DataGridColumnRenderTypes
): Component<any> {
  if (renderAs === undefined)
    throw new Error("DataGridColumnData.renderAs can't be undefined");
  return dataGridCellTypes[renderAs] ?? DGCellRaw;
}
