import { DataGridColumnDataTypes } from "components/basic/data-grid/data-grid-columns";
import { filterFieldsMap as fields } from "./filter-fields-map";
import {
  FilterDefinition,
  FilterOperationMetadata,
} from "./filter-definitions.d";

type FilterDefinitionMap = Record<string, FilterDefinition>;

const operations: Record<string, FilterOperationMetadata> = {
  equals: { operationType: "equals", label: "Equals" },
  notEquals: { operationType: "not-equals", label: "Not Equals" },
  range: { operationType: "range", label: "Range" },
  like: { operationType: "like", label: "Like" },
};

const stringFilter: FilterDefinition = {
  filterDataType: "string",
  operations: [
    { ...operations.equals, fields: [fields.equals] },
    { ...operations.notEquals, fields: [fields.different] },
    { ...operations.like, fields: [fields.like] },
  ],
};

const numberFilter: FilterDefinition = {
  filterDataType: "number",
  operations: [
    { ...operations.equals, fields: [fields.equals] },
    { ...operations.notEquals, fields: [fields.different] },
    { ...operations.range, fields: [fields.greater, fields.lower] },
  ],
};

const booleanFilter: FilterDefinition = {
  filterDataType: "boolean",
  operations: [{ ...operations.equals, fields: [fields.equals] }],
};

const dateFilter: FilterDefinition = {
  filterDataType: "date",
  operations: [
    { ...operations.equals, fields: [fields.equals] },
    { ...operations.notEquals, fields: [fields.different] },
    { ...operations.range, fields: [fields.greater, fields.lower] },
  ],
};

export const filterDefinitions: FilterDefinitionMap = {
  number: numberFilter,
  currency: numberFilter,
  date: dateFilter,
  string: stringFilter,
  boolean: booleanFilter,
};

export function getFilterByDataType(dataType: DataGridColumnDataTypes) {
  const filter = filterDefinitions[dataType];
  if (!filter)
    throw new Error(`There is no filter for column data type: ${dataType}`);
  return filter;
}
