import Base from "./base";

type SeparatorProps = {
  direction?: "row" | "col";
  shading?: "bright" | "dark";
};

const Separator: Component<SeparatorProps> = ({
  direction = "row",
  shading = "dark",
  sx,
}) => {
  return (
    <Base
      sx={{
        flexBasis: 0,
        display: "block",
        borderStyle: "solid",
        borderColor:
          shading == "dark" ? "rgba(0,0,0,0.2)" : "rgba(255,255,255,0.2)",
        /* background:
          color == "dark" ? "rgba(0,0,0,0.2)" : "rgba(255,255,255,0.2)", */
        ...(direction === "row" && {
          height: "100%",
          width: "0.5px",
          minHeight: "100%",
          minWidth: "0.5px",
          borderWidth: "0 1px 0 0",
        }),
        ...(direction === "col" && {
          width: "100%",
          height: "0.5px",
          minWidth: "100%",
          minHeight: "0.5px",
          borderWidth: "1px 0 0 0",
        }),
        ...sx,
      }}
    />
  );
};

export default Separator;
