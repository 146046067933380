import { FlexRow } from "components/basic/flex";
import Text from "components/basic/text";
import { BiChevronRight } from "react-icons/bi";

const BreadCrumb = () => {
  return (
    <FlexRow
      sx={{
        variant: "text.interactables",
        alignItems: "center",
        height: "auto",
        p: 0,
      }}
    >
      {/* <Text>Home</Text>
      <BiChevronRight />
      <Text>Page</Text>
      <BiChevronRight />
      <Text>Sub Page</Text> */}
    </FlexRow>
  );
};

export default BreadCrumb;
