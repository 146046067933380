import { useFormStore } from "components/form/form-store";
import { FlexRow } from "components/basic/flex";
import DropdownField, {
  DropdownFieldOption,
  SelectOption,
} from "components/form/fields/select-field";
import { useEffect } from "react";
import {
  Filter,
  FiltersStore,
  useCurrentFilter,
  useFiltersStore,
} from "components/composed/filters/context/filters-store";
import TextField from "components/form/fields/text-field";
import InputAdornment from "components/form/primitives/adornment";
import Icon from "components/basic/icon";
import { filterDefinitions } from "./context/filter-definitions";
import { FilterDataTypes } from "./context/filter-definitions.d";

type FilterFormProps = {
  columnId: string;
  dataType: FilterDataTypes;
  label?: string;
  onClose: () => void;
  setFilters: FiltersStore["setFilters"];
};
export const FilterForm: Component<FilterFormProps> = ({
  columnId,
  dataType,
  label,
  onClose,
}) => {
  const currentFilter = useCurrentFilter(columnId);
  const formValues = useFormStore((state) => state.formValues);
  const clearForm = useFormStore((state) => state.clearForm);
  const setFieldValue = useFormStore((state) => state.setFieldValue);
  const selectedOperation = formValues.operation;

  const { setFilters } = useFiltersStore();

  const filterDefinition = filterDefinitions[dataType];
  const operations = filterDefinition?.operations;
  const currentOperation =
    operations.find(
      (operation) => operation.operationType === selectedOperation?.value
    ) ?? operations[0];

  useEffect(() => {
    if (currentFilter?.operationType)
      setFieldValue("operation", { value: currentFilter?.operationType });
  }, [currentFilter]);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key == "Enter") {
      handleAddFilter();
      onClose();
    } else if (event.key == "Escape") {
      onClose();
    }
  };

  const handleAddFilter = () => {
    const fieldValues = { ...formValues } as Record<string, any>;
    delete fieldValues.operation;

    const newFilter: Filter = {
      columnId,
      columnLabel: label ?? columnId,
      filterDataType: dataType,
      operationType: currentOperation.operationType,
      fieldValues,
    };

    setFilters({ [columnId]: newFilter });
  };

  const handleChangeOperation = (operationType: string) => {
    clearForm();
    setFieldValue("operation", { value: operationType });
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <>
      <FlexRow sx={{ gap: 1, fontWeight: "normal" }}>
        <DropdownField
          name="operation"
          placeholder="Operation"
          sx={{ flexGrow: 1 }}
          onChange={(selectFieldOption) =>
            handleChangeOperation(
              (selectFieldOption as DropdownFieldOption).value
            )
          }
        >
          {Object.values(operations).map((operation, index) => (
            <SelectOption
              key={operation.operationType}
              value={operation.operationType}
              label={operation.label}
            />
          ))}
        </DropdownField>
      </FlexRow>

      {currentOperation?.fields?.map((field) => {
        const { fieldType, label, icon } = field;
        return (
          <TextField
            key={fieldType}
            name={fieldType}
            placeholder={label}
            leftAdornment={
              <InputAdornment variant="filled" rightBorder>
                <Icon as={icon} />
              </InputAdornment>
            }
            sx={{ flexGrow: 1 }}
          />
        );
      })}
    </>
  );
};
