import { appLayoutContext } from "layout/app/layout_context";
import { SideMenuLink } from "layout/app/side-menu/side-menu-link";
import React, { useEffect } from "react";

const PropertyOverviewPage = () => {
  const { useStore } = appLayoutContext;
  const updateNavigationBar = useStore((state) => state.updateNavigationBar);

  useEffect(() => {
    updateNavigationBar(null, 3);
  }, []);
  return <>Property Overview</>;
};

export default PropertyOverviewPage;
