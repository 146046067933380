import { CardWithSections } from "components/basic/card/card";
import { FlexCenter } from "components/basic/flex";
import StepsProvider from "components/steps-controller/steps-context";
import { earlyAdopterFormContext } from "./context";
import { Steps, steps } from "./steps";
import { Header } from "./header";
import { Content } from "./content";
import { withAuthenticationRequired } from "react-oidc-context";
import { useStepsContext } from "components/steps-controller/steps-context";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { LoadingPage } from "pages/other/loading-page";
import { EarlyAdopterService } from "services/requests";

const EarlyAdopterPage = () => {
  return (
    <FlexCenter sx={{ height: "100%" }}>
      <StepsProvider stepIdOrder={Object.keys(steps)}>
        <Body />
      </StepsProvider>
    </FlexCenter>
  );
};

function Body() {
  const auth = useAuth();

  const stepsContext = useStepsContext();
  const { setCurrentStepId } = stepsContext;

  const [isLoading, setIsLoading] = useState(true);

  async function CallbackInit() {
    try {
      if (!auth.isLoading && auth.isAuthenticated) {
        const response = await EarlyAdopterService.getEarlyAdopter();
        if (response) {
          setCurrentStepId("finalize");
        }
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    CallbackInit();
  }, [auth]);

  if (isLoading) return <LoadingPage />;

  const { Provider: ContextProvider } = earlyAdopterFormContext;
  return (
    <CardWithSections direction="vertical" sx={{ width: "600px" }}>
      <Header />
      <Steps />
      <ContextProvider>
        <Content />
      </ContextProvider>
    </CardWithSections>
  );
}

export default withAuthenticationRequired(EarlyAdopterPage);
