import { AxiosResponse } from "axios";
import { OpenAPI } from "services/requests";
import { request as __request } from "services/requests/core/request";

type JSONServerMethods = "POST" | "GET" | "PATCH" | "PUT" | "DELETE" | "OPTIONS" | "HEAD";

type JsonServerOptions<EntityType> = {
  method: JSONServerMethods;
  body: EntityType;
  filters?: object;
  agency_id?: string;
  id?: string;
  type?: string;
  headers?: Record<string, string>;
  pagination?: JsonServerPagination;
};

type JsonServerPagination = {
  page: string;
  limit: string;
};

type JsonServerMetadata = {
  first: number;
  prev: number | null;
  next: number | null;
  last: number;
  pages: number;
  items: number;
  statistics: Record<string, number | Record<string, number>>;
};

type JsonServerSearchResponse<T> = JsonServerMetadata & { data: T };
type JsonServerSearchResult<T> = { metadata: JsonServerMetadata; data: T };

async function fetchJsonServer<EntityType>(
  serverUrl: string,
  entity: string,
  options: Partial<JsonServerOptions<EntityType>>,
  mediaType?: string
): Promise<EntityType> {
  const { method, body, filters, id, pagination, type, agency_id } = options;
  const agencyIdParam = agency_id ? `/${agency_id}` : "";
  const typeParam = type ? `/${type}` : "";
  const idParam = id ? `/${id}` : "";

  const queryParams: Record<string, string> = {};
  if (pagination) {
    queryParams["_page"] = String(pagination.page ?? 1);
    queryParams["_limit"] = String(pagination.limit ?? 25);
  }

  let queryParamsString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  if (queryParamsString !== "") queryParamsString = "?" + queryParamsString;

  const fullUrl = `/${entity}${agencyIdParam}${typeParam}${idParam}${queryParamsString}`;
  const response: EntityType = 
  await __request(OpenAPI, {
    // headers: { authorization: `Bearer ${OpenAPI.TOKEN}`, ...headers },
    method: method ?? 'GET',
    body: body && JSON.stringify(body),
    url: fullUrl,
    mediaType 
  });
  return response;
}

export function generateCrudFunctions<EntityType>(
  serverUrl: string,
  entity: string,
  type?: string,
  agency_id?: string
) {
  type Options = JsonServerOptions<EntityType>;
  type SearchResult = JsonServerSearchResult<EntityType>;
  type SearchResponse = JsonServerSearchResponse<EntityType>;

  const search = async (
    options?: Pick<Options, "filters" | "pagination">
  ): Promise<SearchResult> => {
    const response = await fetchJsonServer<SearchResponse>(serverUrl, entity, {
      method: "GET",
      ...options,
      agency_id,
      type,
    });

    const { data, ...metadata } = response;
    const result = { data, metadata } as JsonServerSearchResult<EntityType>;
    return result;
  };

  const getById = (options?: Pick<Options, "id">): Promise<EntityType> =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "GET",
      ...options,
      agency_id,
      type,
    });

  const insert = (options: Pick<Options, "body">) =>
    fetchJsonServer<EntityType>(
      serverUrl,
      entity,
      {
        method: "POST",
        ...options,
        agency_id,
        type,
      },
      "application/json"
    );

  const update = (options: Required<Pick<Options, "body" | "id">>) =>
    fetchJsonServer<EntityType>(
      serverUrl,
      entity,
      {
        method: "PUT",
        ...options,
        agency_id,
        type,
      },
      "application/json"
    );

  const patch = (options: Required<Pick<Options, "body" | "id">>) =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "PATCH",
      ...options,
    });

  const exclude = (options: Required<Pick<Options, "id">>) =>
    fetchJsonServer<EntityType>(serverUrl, entity, {
      method: "DELETE",
      ...options,
      agency_id,
      type,
    });

  return {
    search,
    getById,
    insert,
    exclude,
    update,
    patch,
  };
}
